import * as _Types from '../../lib/jsdocTypedefs';

/**
 * @typedef {Object} AvailabilitySearchVariables
 * @property {_Types.PartyMembers} partyMembers
 * @property {_Types.Payload} payload
 * @property {string} pitchId
 * @property {string} pitchTypeId
 * @property {string} productCode
 * @property {string} siteCode
*/

/**
 * @typedef {Object} AvailabilitySearchResponse
 * @property {string[]} offers
 * @property {_Types.Pitch[]} pitchTypes
*/

// Unused export, we just need the typedefs from above imported for JSDoc
const types = {};
export default types;
