import React, { Component } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import { v4 as uuid } from 'uuid';

import { addTiming, types } from '../../lib/timings';
import { buildAmendmentAvailabilityPath, hasUpdatedQuoteFromBooking } from './helpers';
import getAvailabilityOptions from '../../lib/availability';
import getFetchPolicyWithExpiry from '../../lib/getFetchPolicyWithExpiry';

import { dictionaryItem } from '../../hocs/withDictionary';
import GET_AVAILABILITY from './graphql/getAvailability.gql';
import GET_BOOKING_AMENDMENT_AVAILABILITY from './graphql/getBookingAmendmentAvailabilitySearch.gql';
import IbePropTypes from '../../IbePropTypes';
import FetchPolicy from '../../constants/FetchPolicy';

export const AmendsContext = React.createContext({});

const defaultData = { availability: { pitchTypes: [] } };
class AvailabilitySearchQuery extends Component {
  static propTypes = {
    booking: PropTypes.shape(IbePropTypes.booking),
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    children: PropTypes.func.isRequired,
    isAmend: PropTypes.bool.isRequired,
    onCompleted: PropTypes.func.isRequired,
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    variables: PropTypes.shape({
      calendar: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }),
      payload: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      }),
      partyMembers: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.partyMember)),
      siteCode: PropTypes.string,
      productCode: PropTypes.number,
      placeType: PropTypes.number,
      pitchId: PropTypes.string,
      pitchTypeId: PropTypes.string,
    }).isRequired,
  }

  static defaultProps = {
    booking: {},
  }

  fetchStack = [];

  state = {
    availabilitySearch: {
      error: null,
      loading: false,
      data: defaultData,
    },
    availabilityTotal: {
      error: null,
      loading: false,
      data: defaultData,
    },
    inputDateError: null,
  }

  handleError = type => (error) => {
    this.setState({
      [type]: {
        error,
        data: defaultData,
      },
    });
  }

  fetchAvailabilitySearch = async () => {
    const {
      client,
      variables: {
        calendar: {
          start,
        },
        partyMembers,
        siteCode,
        productCode,
        placeType,
      },
    } = this.props;

    const availabilityOptionsVariables = {
      variables: {
        costPerNight: true,
        partyMembers,
        productCode,
        siteCode,
        start,
        placeType,
      },
    };

    const { variables } = getAvailabilityOptions(availabilityOptionsVariables);

    // the async query below can return response out of order of calling
    // we use a uuid to ensure only the last called utilized.
    const fetchRequestUuid = uuid();
    this.fetchStack.push(fetchRequestUuid);
    const cacheKey = `GET_AVAILABILITY${JSON.stringify(availabilityOptionsVariables.variables)}`;

    this.setState({
      availabilitySearchLoading: true,
    });

    try {
      // We use a small amount of cache here to make the UX of
      // clicking back and forth in the calender a little better
      const response = await client.query({
        query: GET_AVAILABILITY,
        fetchPolicy: getFetchPolicyWithExpiry(cacheKey, {
          defaultPolicy: FetchPolicy.CACHE_FIRST,
          expiry: 1000 * 60 * 3, // 3 minutes
          expiryPolicy: FetchPolicy.NETWORK_ONLY,
        }),
        notifyOnNetworkStatusChange: true,
        variables,
      });

      if (fetchRequestUuid === this.fetchStack[this.fetchStack.length - 1]) {
        this.setState({
          availabilitySearch: {
            error: response.error,
            loading: response.loading,
            networkStatus: response.networkStatus,
            data: response?.data ?? defaultData,
            calendarFirstCellDate: variables.start,
          },
        }, () => {
          // TEMP Performance Hooks, TODO can be safely removed at any time
          addTiming(types.AVAILABILITY, 'Load Complete', true);
        });
      }
    } catch (e) {
      this.handleError('availabilitySearch')(e);
    } finally {
      this.setState({
        availabilitySearchLoading: false,
      });
    }
  };

  fetchAvailabilityTotal = async () => {
    const {
      client,
      variables: {
        partyMembers,
        payload: {
          end,
          start,
        },
        siteCode,
        productCode,
        placeType,
      },
    } = this.props;

    // TEMP Performance Hooks, TODO can be safely removed at any time
    addTiming(types.CALENDAR, 'Render Complete', true);

    const datesAreValid = this.validateDates();

    if (!datesAreValid) return null;

    const { variables } = getAvailabilityOptions({
      variables: {
        costPerNight: false,
        partyMembers,
        productCode,
        siteCode,
        start,
        end,
        placeType,
      },
    });

    try {
      const response = await client.query({
        query: GET_AVAILABILITY,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true,
        variables,
      });

      const { data: { availability: { offers } } } = response;
      await this.props.onCompleted({ offers });
      return response;
    } catch (e) {
      this.handleError('availabilityTotal')(e);
      return null;
    }
  };

  fetchAmendmentAvailabilityTotal = async () => {
    const {
      client,
      variables: {
        partyMembers,
        payload: {
          end,
          start,
        },
        pitchId,
        siteCode,
      },
    } = this.props;
    const response = await client.query({
      query: GET_BOOKING_AMENDMENT_AVAILABILITY,
      variables: {
        siteCode,
        start: start.substring(0, 10),
        end: end.substring(0, 10),
        pitchId,
        partyMembers,
        pathBuilder: (path) => buildAmendmentAvailabilityPath(path),
      },
      fetchPolicy: FetchPolicy.NETWORK_ONLY,
    });
    return response;
  }

  clearAvailabilityTotal = () => {
    this.setState({
      availabilityTotal: defaultData,
    });
  }

  /**
   * Handles retrieving prices for amended and non-amended stays
   * without per-night pricing
   * @returns {void}
   */
  fetchQuotePrice = async () => {
    const {
      variables,
      router,
      isAmend,
      booking,
    } = this.props;

    this.setState({
      availabilityTotal: {
        ...this.state.availabilityTotal,
        loading: true,
      },
    });

    /*
      Handling for deciding what requests we make for non-nightly pricing.

      Via amends we only call the GET_BOOKING_AMENDMENT_AVAILABILITY endpoint
      if we have changed any key booking features. We get the initial nightly
      pricing from the GET_CURRENT_USER_BOOKING_DETAILS call where it's
      returned on the pitch
    */
    let response;
    try {
      if (isAmend) {
        // Don't run the API request if there's no nights to get prices for
        const datesAreValid = this.validateDates();
        if (!datesAreValid) {
          response = this.clearAvailabilityTotal();
          return;
        }
        // If the user has updated their quote from their booking
        // we call a different API endpoint specifically for amends
        // pricing
        const hasUpdatedComponents = hasUpdatedQuoteFromBooking(
          router,
          variables,
          booking,
        );
        if (!hasUpdatedComponents) {
          response = this.clearAvailabilityTotal();
          return;
        }
        response = await this.fetchAmendmentAvailabilityTotal();
      } else {
        response = await this.fetchAvailabilityTotal();
      }
    } catch (error) {
      this.setState({
        availabilityTotal: {
          loading: false,
        },
      });
    }

    this.setState({
      availabilityTotal: {
        ...response,
        data: response?.data ?? defaultData,
      },
    });
  }

  validateDates = () => {
    const {
      variables: {
        payload: {
          end,
          start,
        },
      },
    } = this.props;

    if (!start && !end) {
      const inputDateError = new Error();
      inputDateError.message = dictionaryItem('AvailabilityCalendar', 'Date', 'Missing', 'Error');
      this.setState({ inputDateError });
      return false;
    }

    if (start === end || !end) {
      const inputDateError = new Error();
      inputDateError.message = dictionaryItem('AvailabilityCalendar', 'DepartureDate', 'Missing', 'Error');
      this.setState({ inputDateError });
      return false;
    }

    this.setState({ inputDateError: null });

    return true;
  }

  getAmendingSiteFromBooking = () => {
    const { booking, isAmend, router } = this.props;
    if (!isAmend) return null;
    const { componentId } = router.query ?? {};
    const amendingSite = booking.campsiteBookings.find(
      (campsiteBooking) => campsiteBooking.id === componentId,
    );
    return amendingSite ?? null;
  }

  render() {
    const { booking, children, variables } = this.props;
    const {
      availabilitySearch,
      availabilitySearchLoading,
      availabilityTotal,
      inputDateError,
    } = this.state;

    // Provides the sub-tree with the site we are currently amending
    // (if any). Saves elsewhere having to loop through the booking
    const amendingSite = this.getAmendingSiteFromBooking();

    // If there's no error and data then assume it's loading
    const loading = availabilitySearch.loading
      || (!availabilitySearch.error && !availabilitySearch.data);

    return (
      <AmendsContext.Provider
        value={{
          amendingSite,
          availabilityTotal,
          availabilitySearch,
          booking,
          searchVariables: variables,
        }}
      >
        {children({
          inputDateError,
          availabilitySearch,
          availabilitySearchLoading,
          availabilityTotal,
          loading,
          fetchAvailabilitySearch: this.fetchAvailabilitySearch,
          fetchAvailabilityTotal: this.fetchQuotePrice,
        })}
      </AmendsContext.Provider>
    );
  }
}

export default compose(
  withApollo,
  withRouter,
  (WrappedComponent) => (props) => (
    // bypass withApollo and use custom ref, this is used to allow parent
    // QuoteCampsite to call fetchAvailabilityTotal externally.
    // eslint-disable-next-line react/prop-types
    <WrappedComponent {...props} ref={props.availabilitySearchQueryRef} />
  ),
)(AvailabilitySearchQuery);
