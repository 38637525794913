import styled, { css } from 'styled-components';

import Drawer from '../../ui/Drawer';

import { above, below } from '../../../styles/libs';

/**
 * Locks the height of the element to the viewport height
 * which allows for overlaying Drawers and Site Cards
 */
const lockHeight = inView => (...styles) => {
  if (inView) return css`${styles}`;
  return css`
    position: absolute;
    height: 100%;
    overflow: hidden;
  `;
};

export default styled(Drawer)`
  position: relative;

  ${({
    expand, inView, theme, isBookingWidget, show,
  }) => css`
    display: ${show ? 'block' : 'none'};

    ${below(theme.DESKTOP)(lockHeight(inView)``)}

    ${above(theme.TABLET)`
      ${!expand && !isBookingWidget && 'max-width: 600px;'}
      ${expand && 'max-width: 1080px;'}
      ${!isBookingWidget && `width: ${theme.TWO_PANEL_WEIGHTED_LEFT_1};`}
    `};

    ${above(theme.DESKTOP)`
      width: ${isBookingWidget ? theme.ONE_PANEL : theme.TWO_PANEL_WEIGHTED_LEFT_1};
    `};
  `}
`;

export const DrawerSearchInner = styled.div`
  height: 100%;
  width: 100%;
`;

export const DrawerHandleBody = styled.div`
  background-color: ${props => props.theme.COLOR_GRAY_LIGHTER};
  width: 100%;
  height: 100%;
`;

export const SearchHandle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const SearchFormWrapper = styled.div`
  display: ${props => (props.visible ? 'flex' : 'none')};
  width: 100%;
  height: 100%;
`;
