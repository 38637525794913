import { differenceInDays } from 'date-fns';
import { sum } from 'lodash';

import { calculateCalendarMonthOffset, getMonthOffsetFromDateInMonth, getStartAndEndOfMonth } from '../../../lib/dates';

import * as _Types from '../../../lib/jsdocTypedefs';
import * as _AvailabilityTypes from '../../Availability/types';

/**
 * Overwrites a certain portion of an array with another
 * @param {number[]} originalArray
 * @param {number} start
 * @param {number[]} newArray
 * @returns {number[]}
 */
const replaceArraySection = (
  originalArray,
  start,
  newArray,
) => {
  const originalCopy = [...originalArray];
  // Splice runs inline so it modifies the original array
  Array.prototype.splice.apply(originalCopy, [start, newArray.length].concat(newArray));
  return originalCopy;
};

/**
 * Extracts the actual data from the Apollo API responses
 * for availability queries
 * @param {_Types.AvailabilitySearchResponse} availabilityQuery
 * @returns {_Types.AvailabilityType}
 */
const parseAvailabilityQuery = (availabilityQuery) => availabilityQuery?.data?.availability ?? {};

/**
 * Gets the currently selected pitch from an availability response
 * @param {_Types.AvailabilitySearchResponse} availabilityQuery
 * @param {string} pitchCode
 * @returns {_Types.Pitch}
 */
const getPitchFromAvailability = (availabilityQuery, pitchCode) => {
  const parsedAvailability = parseAvailabilityQuery(availabilityQuery);
  return parsedAvailability?.pitchTypes?.find((availPitch) => availPitch.code === pitchCode) ?? {};
};

/**
 * Parses the actual price, replacing selected dates with values
 * returned from the respective API routes.
 * 1 -  When amending and making changes we use the prices that come back
 *      on the availabilityTotal request
 * 2 -  When amending and not making any changes we use the nightlyPrices
 *      from the current pitch
 * @param {{
 *  start: string,
 *  end: string,
 * }} selectedDates
 * @param {_Types.BookingDetails} booking
 * @param {_AvailabilityTypes.AvailabilitySearchResponse} availabilitySearch
 * @param {_AvailabilityTypes.AvailabilitySearchResponse} availabilityTotal
 * @param {_Types.Pitch} pitch
 * @param {_Types.Router} router
 * @returns {_Types.Pitch[]}
 */
export const parseAmendsAvailabilityPrices = (
  selectedDates,
  booking,
  availabilitySearch,
  availabilityTotal,
  pitch,
  router,
  calendarStartDate,
) => {
  const searchAvailability = parseAvailabilityQuery(availabilitySearch);
  let pitchesWithPricing = [...searchAvailability.pitchTypes];
  if (!booking) return pitchesWithPricing;

  const { componentId } = router.query;
  const bookingSite = booking.campsiteBookings.find((campsite) => campsite.id === componentId);
  if (!bookingSite) return pitchesWithPricing;
  const bookingPitch = bookingSite.pitches.find((bookedPitch) => bookedPitch.code === pitch.code);

  if (!bookingPitch) return pitchesWithPricing;

  pitchesWithPricing = pitchesWithPricing.map((pitchWithPrice) => {
    const copiedPitchWithPrice = { ...pitchWithPrice };
    if (pitchWithPrice.code !== pitch.id && pitchWithPrice.code !== pitch.code) {
      return pitchWithPrice;
    }

    // 1 - Handling for if we're just amending and haven't made any changes
    // This should always be run for every amend, and it can be overwritten
    // if required with availabilityTotal when changes are made
    const nightlyPricesWithDates = bookingPitch.nightlyPrices;
    const nightlyPrices = nightlyPricesWithDates.map((datePrice) => datePrice.price);
    // The indexes can be offset by a certain amount due to the calendar holding days
    // for multiple months
    const monthOffset = getMonthOffsetFromDateInMonth(calendarStartDate);
    const firstNightlyPriceDate = bookingPitch.nightlyPrices[0].date;
    // Calculates the index in nightlyPrices where we cross a month boundary
    const bookingCrossesMonthAtIndex = nightlyPricesWithDates.findIndex(
      (price) => price.date === calendarStartDate,
    );
    const bookingDoesCrossMonthBoundary = bookingCrossesMonthAtIndex !== -1;
    const truncatedNightlyPrices = nightlyPrices.slice(bookingCrossesMonthAtIndex);
    const startDay = bookingDoesCrossMonthBoundary
      ? monthOffset
      : Number(firstNightlyPriceDate.substring(8, 10)) + monthOffset - 1;
    // Now we need to change the prices array to have the booked prices on the booked dates
    // for both members and non-members
    const { memberAvailability, nonMemberAvailability } = copiedPitchWithPrice;
    const actualNightlyPrices = bookingDoesCrossMonthBoundary
      ? truncatedNightlyPrices
      : nightlyPrices;

    let updatedMemberPrices = replaceArraySection(
      memberAvailability,
      startDay,
      actualNightlyPrices,
    );

    let updatedNonMemberPrices = replaceArraySection(
      nonMemberAvailability,
      startDay,
      actualNightlyPrices,
    );

    // 2 - Handling for if the user has made changes when amending
    const availabilityTotalPitches = parseAvailabilityQuery(availabilityTotal).pitchTypes ?? [];
    if (availabilityTotalPitches.length) {
      const {
        memberAvailability: totalMemberAvailability,
        nonMemberAvailability: totalNonMemberAvailability,
      } = availabilityTotalPitches.find(
        (totalPitch) => totalPitch.code === pitch.id,
      ) ?? {};
      const { start: selectedStartDate } = selectedDates;
      // The index in availability where we should start to overwrite prices
      const startIndex = bookingDoesCrossMonthBoundary
        ? monthOffset
        : Number(selectedStartDate?.substring(8, 10) ?? 0) + monthOffset - 1;
      // Get the index at which in the nightly prices array we pass the month boundary.
      // If we don't cross just return 0 so we insert the whole price array
      const monthBoundaryIndex = bookingDoesCrossMonthBoundary
        ? bookingCrossesMonthAtIndex
        : 0;
      // For both member and non-member, overwrite prices with the prices
      // we get back from the new amendment endpoint
      if (totalMemberAvailability) {
        updatedMemberPrices = replaceArraySection(
          updatedMemberPrices,
          startIndex,
          totalMemberAvailability.slice(monthBoundaryIndex),
        );
      }
      if (totalNonMemberAvailability) {
        updatedNonMemberPrices = replaceArraySection(
          updatedNonMemberPrices,
          startIndex,
          totalNonMemberAvailability.slice(monthBoundaryIndex),
        );
      }
    }

    copiedPitchWithPrice.memberAvailability = updatedMemberPrices;
    copiedPitchWithPrice.nonMemberAvailability = updatedNonMemberPrices;

    return copiedPitchWithPrice;
  });

  return pitchesWithPricing;
};

/**
 * Calculates the total stay price from the availability search
 * and total requests
 * @param {_Types.AvailabilitySearchResponse} availabilityTotal
 * @param {_Types.AvailabilitySearchResponse} availabilitySearch
 * @param {{
 *  start: string,
 *  end: string,
 * }} selectedDates
 * @param {_Types.Pitch} pitch
 * @returns {number} totalPitchPrice
 */
export const calculateTotalPriceFromAvailability = (
  availabilityTotal,
  availabilitySearch,
  selectedDates,
  pitch,
  isMember,
) => {
  const availabilityTotalPitch = getPitchFromAvailability(availabilityTotal, pitch.id);
  // Array to track the nightly prices which is summed at the end
  // to get the total stay price
  let nightlyPrices = [];
  const pricesKey = isMember ? 'memberAvailability' : 'nonMemberAvailability';
  // If we have valid prices on availabilityTotal use that
  if (
    availabilityTotalPitch &&
    availabilityTotalPitch?.code &&
    availabilityTotalPitch?.[pricesKey]
  ) {
    nightlyPrices = nightlyPrices.concat(availabilityTotalPitch[pricesKey]);
  } else {
    // If we don't have prices in availabilityTotal, get them from availabilitySearch
    const { start: startDate, end: endDate } = selectedDates;
    const { start: startOfMonth } = getStartAndEndOfMonth(startDate);
    const calendarStartOffset = calculateCalendarMonthOffset(startOfMonth);
    const startIndex =
      Number(startDate?.substring(8, 10) ?? 0) + calendarStartOffset - 1;
    const availabilitySearchPitch = getPitchFromAvailability(
      availabilitySearch,
      pitch.id,
    );
    const allPrices = availabilitySearchPitch[pricesKey];
    if (allPrices?.length > 0) {
      const stayLength = Math.abs(differenceInDays(startDate, endDate));
      const relevantPrices = allPrices.slice(startIndex, startIndex + stayLength);
      nightlyPrices = relevantPrices;
    }
  }

  return sum(nightlyPrices);
};

export default parseAmendsAvailabilityPrices;
