import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import Router, { withRouter } from 'next/router';
import {
  ApolloConsumer, compose, graphql,
} from 'react-apollo';
import { format } from 'date-fns';

import GET_CAMPSITE, {
  getCampsiteOptions,
} from '../../config/graphql/getCampsite';
import GET_USER_STATUS from '../../config/graphql/getUser';
import RESET_SESSION from '../Quote/graphql/resetSession';
import { BASKET_STATES } from '../../config/products';
import scrollToTop from '../../lib/scrollToTop';
import isLoggedIn from '../../lib/isLoggedIn';
import formatPrice from '../../lib/formatPrice';
import updateRouterQuery from '../../lib/updateRouterQuery';
import testingAttr from '../../lib/testingAttr';
import { cleanPartyMembers } from '../../lib/parsePayload';
import queryHelper from '../../lib/queryHelper';
import { quoteHasNonMemberExclusiveProducts } from '../../lib/helpers/quote';
import routes from '../../constants/routes';
// TODO: Uncomment when BED is complete
// import { parseGuestsFromQuotePayload } from '../../lib/guestHelpers';

import ErrorTypeMessage from '../ErrorTypeMessage';
import { Col, Row } from '../ui/Grid';
import Text from '../ui/Text';

import StyledAvailabilityDetailsFooter from './AvailabilityDetailsFooter.style';
import ButtonBrand from '../ui/Button/ButtonBrand.style';

import { RENEWAL_STATUSES } from '../../config/membershipStatus';
import { DATE_FORMAT_DEFAULT } from '../../config/locale';

import AddToBasket from '../AddToBasket/AddToBasket';
import { dataLayerManager } from '../../lib/dataLayer';
import {
  addToBasketRedirect,
  addToBasketRedirectToCheckout,
  quoteHasOverlapBooking,
} from '../Basket/helpers';
import quoteMembershipTypes, { getENumWithValue } from '../../config/quoteMembershipTypes';
import IbePropTypes from '../../IbePropTypes';
import FetchPolicy from '../../constants/FetchPolicy';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import { ButtonBordered } from '../ui/Button';
import { AMEND_MODALS, getModalProps } from '../../lib/helpers/amend';
import AvailabilityDetailsCostSummary from './AvailabilityDetailsCostSummary';
import NoticeModal from '../ui/Modal/NoticeModal/NoticeModal';

const quotePropTypes = {
  errataAccepted: PropTypes.bool.isRequired,
};

export function parsePayload(payload = {}, componentId, isAmend) {
  let specialRequests;
  if (payload.specReq?.length) {
    specialRequests = payload.specReq;
  }

  const type = (payload.campsite && !Number.isNaN(payload.campsite.type))
    ? payload.campsite.type : 0;

  const advancedPayload = payload.mainPayload ? ({
    partyMembers: cleanPartyMembers(payload.mainPayload.partyMembers),
    outfit: payload.mainPayload.outfit,
  }) : {};

  const advancedGuests = payload.mainPayload ? payload.partyMembers : undefined;

  return {
    campsiteBooking: {
      campsite: {
        siteCode: payload.siteCode,
        type,
      },
      pitches: [
        {
          bookingDates: {
            fromDate: format(payload.start, DATE_FORMAT_DEFAULT),
            toDate: format(payload.end, DATE_FORMAT_DEFAULT),
          },
          id: payload.id || componentId || 0,
          code: payload.pitchTypeId,
          supplements: payload.supplements?.map(({ id, quantity }) => ({
            id,
            quantity,
          })).filter(item => !!item.id && item.id.includes('<locator>')),
          guests: payload.guests,
          arrivalTime: payload.arrivalTime,
          advancedGuests,
          // main payload is the parent pitch payload if editing an advanced pitch
          parentPitchId: payload.mainPayload ? payload.parentPitchId : undefined,
          outfit: payload.mainPayload ? payload.outfit : undefined,
          specialRequests,
          eventType: payload.eventType,
          cancelReasonId: payload.cancelReasonId,
        },
      ],
    },
    errataAccepted: payload.errataAccepted,
    membershipType: payload.membershipType
      || getENumWithValue(quoteMembershipTypes.NotLoggedInOrNonMember),
    outfit: payload.mainPayload ? payload.mainPayload.outfit : payload.outfit,
    partyMembers: (isAmend && !payload.partyChanged) ?
      undefined : cleanPartyMembers(payload.partyMembers),
    productCode: payload.productCode,
    ...advancedPayload,
  };
}

const parseAdditionalPitches = (campingPayloads, componentId) => campingPayloads
  .map((campingPayload) => ({
    id: campingPayload.id || componentId || 0,
    bookingDates: {
      fromDate: format(campingPayload.start, DATE_FORMAT_DEFAULT),
      toDate: format(campingPayload.end, DATE_FORMAT_DEFAULT),
    },
    code: campingPayload.pitchTypeId,
    supplements: campingPayload.supplements.map(({ id, quantity }) => ({
      id,
      quantity,
    })).filter(item => !!item.id && item.id.includes('<locator>')),
    arrivalTime: campingPayload.arrivalTime,
    advancedGuests: campingPayload.partyMembers,
    outfit: campingPayload.outfit,
    specialRequests: campingPayload.specReq?.length ? campingPayload.specReq : [],
    parentPitchId: campingPayload.parentPitchId,
    eventType: campingPayload.eventType,
  })).filter((payload) => !payload.id); // Remove existing camping pitches from payload

const parseAdvancedPayload = (payload = {}, payloads, componentId) => {
  const mainPayload = parsePayload(payloads?.touring, componentId);
  const additionalPitches = parseAdditionalPitches(payloads?.camping);
  additionalPitches.forEach((additionalPitch) => {
    mainPayload.campsiteBooking.pitches.push(additionalPitch);
  });
  mainPayload.errataAccepted = payload.errataAccepted;
  return mainPayload;
};

class AvailabilityDetailsFooter extends PureComponent {
  static propTypes = {
    additionalPrice: PropTypes.number,
    advancedGuestMode: PropTypes.bool,
    campingIsUpsellEnabled: PropTypes.bool.isRequired,
    campsiteAddress: PropTypes.shape({
      country: PropTypes.string.isRequired,
    }).isRequired,
    errataMissedWarning: PropTypes.bool,
    pitchName: PropTypes.string,
    refetchQuote: PropTypes.func,
    showUpSell: PropTypes.bool.isRequired,
    totalPrice: PropTypes.number.isRequired,
    upSellSaving: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onlyExtras: PropTypes.bool.isRequired,
    handleCheckMembership: PropTypes.func.isRequired,
    quote: PropTypes.shape(IbePropTypes.quote),
    userStatus: PropTypes.shape({
      user: PropTypes.shape(IbePropTypes.user),
    }),
    onAdditionalCampingPitch: PropTypes.func,
    payloads: PropTypes.shape(IbePropTypes.payloads),
    productCode: PropTypes.number.isRequired,
    validatePayload: PropTypes.func,
    onProductTypeMismatch: PropTypes.func.isRequired,
    amendmentFee: PropTypes.number,
    previousTotalPrice: PropTypes.number,
    resetSession: PropTypes.func,
    ...quotePropTypes,
  };

  static defaultProps = {
    additionalPrice: 0,
    advancedGuestMode: false,
    errataMissedWarning: false,
    refetchQuote() {},
    upSellSaving: 0,
    quote: {},
    userStatus: {
      user: {},
    },
    pitchName: '',
    onAdditionalCampingPitch: undefined,
    payloads: null,
    validatePayload: null,
    amendmentFee: 0,
    previousTotalPrice: 0,
    resetSession: () => {},
  };

  state = {
    error: null,
    discardModalOpen: false,
  };

  componentDidUpdate({ errataMissedWarning: prevWarning }) {
    const { errataMissedWarning } = this.props;

    if (prevWarning && !errataMissedWarning) {
      this.clearErrataError();
    }
  }

  handleError = (error) => {
    this.setState({ error });

    // If error is because errata hasn't been accepted
    if (
      this.state.error &&
      this.state.error.networkError &&
      this.state.error.networkError.result &&
      this.state.error.networkError.result.ErrataAccepted) {
      this.props.handleMissedErrata();
    }
  };

  clearErrataError = () => {
    const { error } = this.state;
    if (
      error &&
      error.networkError &&
      error.networkError.result &&
      error.networkError.result.ErrataAccepted
    ) {
      this.setState({ error: null });
    }
  }

  handleAddToBasketClickCallBack = (client, isQuickCheckout, quote) => {
    const {
      router, campsiteName, toggleBasket, showUpSell, upSellSaving,
    } = this.props;
    const hasOverlap = quoteHasOverlapBooking(quote);
    const expandedBasket = !!router.query.bookingId; // only expand basket on amend mode
    if (isQuickCheckout && !hasOverlap) {
      addToBasketRedirectToCheckout(this.pushToDataLayer);
    } else {
      addToBasketRedirect(
        client,
        router.query,
        campsiteName,
        toggleBasket,
        this.pushToDataLayer,
        {
          showUpSell,
          upSellSaving,
        },
        expandedBasket,
      );
    }
  };

  handleAddToBasketClick = (client, isQuickCheckout = false, quote) => {
    const { handleCheckMembership } = this.props;
    handleCheckMembership(() => {
      this.handleAddToBasketClickCallBack(client, isQuickCheckout, quote);
    }, undefined, undefined, isQuickCheckout);
  }

  toggleDialog = (dialog, open) => {
    const { client } = this.props;

    if (open) {
      scrollToTop();
    }

    client.writeData({ data: { [dialog]: open } });
  }

  handleQuickCheckout = (client) => {
    const { quote, router, userStatus } = this.props;
    const { campaignCode } = router.query;

    const isOverseas = quote?.basketState === BASKET_STATES.OVERSEAS;
    const showProceed = quoteHasNonMemberExclusiveProducts(quote);
    if (isLoggedIn() &&
    RENEWAL_STATUSES.includes(userStatus?.user?.membershipType)) {
      this.toggleDialog('membershipExpiredPopUp', {
        __typename: 'MembershipExpiredPopUp',
        open: true,
        isOverseas,
        showProceed,
      });
      return;
    }
    this.pushToDataLayer();

    // User is not logged in.
    if (!isLoggedIn() || !userStatus?.user?.canProceedToBooking) {
      updateRouterQuery(routes.sites, {
        componentId: null,
        campsiteId: null,
        campsiteName: null,
        location: null,
        siteCode: null,
        campaignCode,
      });

      scrollToTop();

      client.writeData({
        data: {
          loginPopUp: {
            __typename: 'LoginPopUp',
            open: true,
            title: '',
            prompt: '',
            redirectTo: routes.checkout,
          },
        },
      });

      return;
    }

    // User is logged in and is a member.
    if (userStatus?.user?.canProceedToBooking) {
      Router.push({
        pathname: routes.checkout,
        query: { campaignCode },
      });
    }
  };

  pushToDataLayer = () => {
    dataLayerManager.pushAddToCart(dataLayerManager.subCategory.CAMPSITE, {
      ...this.props,
      id: this.props.campsite.id,
      name: this.props.campsiteName,
      price: this.props.totalPrice,
      subCategory: dataLayerManager.subCategory.CAMPSITE,
      outfit: {
        vehicleType: this.props.outfit.vehicleType,
        towType: this.props.outfit.towType,
      },
      rating: this.props.campsite.rating,
      address: {
        country: this.props.campsiteAddress.country,
      },
      type: this.props.campsite.type,
      quantity: 1,
    });
  }

  renderError = (error) => {
    if (!error) {
      return null;
    }
    const { preAmendmentChecks } = error?.networkError?.result ?? {};
    const { preventBookingIssues, warnBookingIssues } = preAmendmentChecks?.[0] ?? {};

    if (preAmendmentChecks?.length || warnBookingIssues?.length) {
      const issue = preventBookingIssues?.[0] || warnBookingIssues?.[0];
      const { displayText, bookingCodes } = issue;
      return (
        <ErrorTypeMessage marginBottom data-error="true">
          {`${displayText} Booking code${bookingCodes.length > 1 ? 's' : ''}: ${bookingCodes.join(', ')}`}
        </ErrorTypeMessage>
      );
    }
    return (
      <ErrorTypeMessage data-error="true" error={error} marginBottom />
    );
  }

  getAddToBasketText = (client) => {
    if (this.props.router.query.bookingId) {
      return getDictionaryItem(client, 'AvailabilityAmend__ConfirmChanges__Button');
    }
    if (this.props.router.query.componentId) {
      return getDictionaryItem(client, 'AvailabilityDetails__UpdateBasket__Button');
    }
    return getDictionaryItem(client, 'AvailabilityDetails__AddToBasket__Button');
  }

  setDiscardModal = (state) => {
    this.setState({
      discardModalOpen: state,
    });
  }

  discardChanges = () => {
    this.props.resetSession().finally(() => {
      Router.push(`${routes.myBookingsUpcoming}/${this.props.quote.bookingReference}`);
    });
  }

  render() {
    const {
      advancedGuestMode,
      campingIsUpsellEnabled,
      canAddMoreCampingPitches,
      disabled,
      handleAddMoreCampingPitches,
      loading,
      quoteCount,
      onCompleted,
      onlyExtras,
      onSubmit,
      router,
      onAdditionalCampingPitch,
      userStatus,
      payloads,
      validatePayload,
      onProductTypeMismatch,
      amendmentFee,
      ...payload
    } = this.props;

    const { error } = this.state;

    const parsedPayload = advancedGuestMode ? parseAdvancedPayload(
      payload,
      payloads,
      this.props.router.query.componentId,
    ) :
      parsePayload(
        payload,
        this.props.router.query.componentId,
        this.props.quote?.isBookingAmendmentQuote,
      );

    if (!userStatus?.user) return null;

    const isAmend = !!router.query.bookingId;

    const modalProps = getModalProps(AMEND_MODALS.AMEND_CANCEL);

    return (
      <StyledAvailabilityDetailsFooter>
        {isAmend ? (
          <AvailabilityDetailsCostSummary
            retainedDeposit={amendmentFee}
            prevStayTotal={this.props.previousTotalPrice}
            stayTotal={this.props.totalPrice}
          />
        ) : (
          <Text
            marginBottom
            size="2.25rem"
            {...testingAttr('availability-details__total-price')}
          >
            Total: <b>{formatPrice(this.props.totalPrice)}</b>
          </Text>
        )}

        {error && this.renderError(error)}
        <ApolloConsumer>
          {client => (
            <>
              {canAddMoreCampingPitches && isAmend && (
                <Row size="small">
                  <Col>
                    <ButtonBrand
                      block
                      type="button"
                      onClick={() => {
                        scrollToTop();
                        handleAddMoreCampingPitches();
                      }}
                      size="xl"
                      dictionary={dictionaryItem('AvailabilityDetails', 'AddMoreCampingPitches')}
                    />
                  </Col>
                </Row>
              )}
              <Row size="small">
                {canAddMoreCampingPitches && !isAmend &&
                  <Col>
                    <ButtonBrand
                      block
                      type="button"
                      onClick={() => {
                        scrollToTop();
                        handleAddMoreCampingPitches();
                      }}
                      size="xl"
                      dictionary={dictionaryItem('AvailabilityDetails', 'AddMoreCampingPitches')}
                    />
                  </Col>
                }
                {(!campingIsUpsellEnabled && !canAddMoreCampingPitches && isLoggedIn()
                  && !isAmend) &&
                  <Col>
                    <AddToBasket
                      block
                      disabled={disabled}
                      onCompleted={onCompleted}
                      onError={this.handleError}
                      onSubmit={onSubmit}
                      onSuccess={
                        (response) => this.handleAddToBasketClick(
                          client,
                          true,
                          response?.data?.quoteResponse?.quote,
                        )}
                      handleCheckMembership={this.props.handleCheckMembership}
                      payload={parsedPayload}
                      validatePayload={validatePayload}
                      {...testingAttr('availability-details__quick-checkout')}
                    >
                      {getDictionaryItem(client, 'AvailabilityDetails__QuickCheckout__Button')}
                    </AddToBasket>
                  </Col>
                }
                {isAmend && (
                  <Col>
                    <ButtonBordered
                      block
                      type="button"
                      onClick={() => this.setDiscardModal(true)}
                      size="xl"
                      dictionary={dictionaryItem('AvailabilityAmend', 'DiscardChanges')}
                    />
                  </Col>
                )}
                <Col>
                  {campingIsUpsellEnabled &&
                    onAdditionalCampingPitch &&
                    !advancedGuestMode &&
                    !canAddMoreCampingPitches ? (
                      <ButtonBrand
                        block
                        type="button"
                        onClick={onAdditionalCampingPitch}
                        size="xl"
                        disabled={!parsedPayload?.errataAccepted}
                        dictionary={dictionaryItem('AvailabilityDetails', 'Continue')}
                      />
                    ) : (
                      <AddToBasket
                        block
                        disabled={disabled}
                        onCompleted={onCompleted}
                        onError={this.handleError}
                        onSubmit={onSubmit}
                        onSuccess={(quote) => this.handleAddToBasketClick(client, false, quote)}
                        handleCheckMembership={this.props.handleCheckMembership}
                        validatePayload={validatePayload}
                        payload={parsedPayload}
                        onProductTypeMismatch={onProductTypeMismatch}
                        {...testingAttr('availability-details__add-to-basket')}
                        isAmend={isAmend}
                      >
                        <span>{this.getAddToBasketText(client)}</span>
                      </AddToBasket>
                    )}
                </Col>
              </Row>
            </>
          )}
        </ApolloConsumer>
        <NoticeModal
          {...modalProps}
          open={this.state.discardModalOpen}
          onPrimaryAction={this.discardChanges}
          onSecondaryAction={() => this.setDiscardModal(false)}
          onClickOutside={() => this.setDiscardModal(false)}
          onClose={() => this.setDiscardModal(false)}
        />
      </StyledAvailabilityDetailsFooter>
    );
  }
}

export default compose(
  withRouter,
  queryHelper(GET_CAMPSITE, getCampsiteOptions),
  graphql(GET_USER_STATUS, {
    name: 'userStatus',
    options: {
      fetchPolicy: FetchPolicy.CACHE_ONLY,
    },
    skip: props => !isLoggedIn(),
  }),
  graphql(RESET_SESSION, {
    props: ({ mutate }) => ({
      resetSession: () => mutate({
        refetchQueries: ['Quote'],
      }),
    }),
  }),
)(AvailabilityDetailsFooter);
