import moment from 'moment';
import { areRangesOverlapping } from 'date-fns';
import { differenceInDays } from '../../lib/dates';
import { DATE_FORMAT_INPUT } from '../../config/locale';

export function parseDateString(str) {
  let dateString = str;
  if (!dateString) {
    dateString = '--';
  }
  const components = dateString.split('-');
  return {
    year: components[0] ?? '',
    month: (components[1] !== '') ? parseInt(components[1], 10) - 1 : '',
    day: (components[2] !== '') ? parseInt(components[2], 10) : '',
  };
}

export function bookingHasOverlaps(payload, bookings) {
  const { id, start, end } = payload;
  const otherBookings = bookings.filter((booking) => booking.id !== id);
  if (!otherBookings.length || !start || !end || start === end) {
    return false;
  }
  let hasOverlaps = false;
  otherBookings.forEach((booking) => {
    booking.pitches.forEach((pitch) => {
      if (areRangesOverlapping(
        start, end, pitch.bookingDates.fromDate, pitch.bookingDates.toDate,
      )) {
        hasOverlaps = true;
      }
    });
  });
  return hasOverlaps;
}

export const bookingReducedNights = (payload, pitches) => {
  const pitch = pitches?.find(({ id }) => id === payload?.id);
  if (!pitch || !payload?.start || !payload?.end) {
    return false;
  }
  const numNights = differenceInDays(pitch.bookingDates.toDate, pitch.bookingDates.fromDate);
  const newNumNights = differenceInDays(payload.end, payload.start);
  const datesOverlapping = areRangesOverlapping(pitch.bookingDates.fromDate,
    pitch.bookingDates.toDate, payload.start, payload.end);
  return newNumNights < numNights || !datesOverlapping;
};

export default parseDateString;

/**
 * Determines the message to display based on the booking conditions.
 *
 * @param {boolean} isOverseas - Indicates if the booking is overseas.
 * @param {string} penaltyDate - The penalty date in string format.
 * @param {Function} getDictionaryItem - The function to fetch dictionary items.
 * @returns {object} - An object containing the message and link from the dictionary.
 */
export function getAvailabilityAmendImportantInfoMessage(
  isOverseas, penaltyDate, getDictionaryItem, client,
) {
  const linkResult = getDictionaryItem(client, 'AvailabilityAmend__ImportantInfo__Link__Title');

  // '.' indicates on the link title indicates the client has turned the link feature off
  // and so don't show value
  const linkTitleValue = linkResult === '.'
    ? null
    : linkResult;

  if (isOverseas) {
    return {
      message: getDictionaryItem(client, 'AvailabilityAmend__ImportantInfo__IsOverseasBooking__RichText'),
      linkTitle: linkTitleValue,
    };
  }

  const isPenaltyDateValid = moment(penaltyDate).isValid();
  if (!isPenaltyDateValid) {
    return {
      message: getDictionaryItem(client, 'AvailabilityAmend__ImportantInfo__PenaltyDateIssue__RichText'),
      linkTitle: null,
    };
  }

  const formattedPenaltyDate = moment(penaltyDate).format(DATE_FORMAT_INPUT);
  const message = getDictionaryItem(client, 'AvailabilityAmend__ImportantInfo__IsUK__RichText')
    .replace('{{penaltyDate}}', formattedPenaltyDate);

  return { message, linkTitle: linkTitleValue };
}
