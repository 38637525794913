import styled from 'styled-components';
import { focusStateInset } from '../ui/Form/Form.style';
import { above } from '../../styles/libs';

export const MAP_FOOTER_SINGLE_ROW_BREAKPOINT = '108rem';
export const MAP_FOOTER_TIGHT_GUTTER_BREAKPOINT = 600;

export default styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
`;

export const ZoomControls = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  margin: 1rem;
  z-index: 1;
`;

export const ZoomAction = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  border: 3px solid ${({ theme }) => theme.COLOR_BRAND_PRIMARY};
  border-radius: 5px;
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};
  width: 3rem;
  height: 3rem;

  &:disabled {
    cursor: default;
    background-color: ${({ theme }) => theme.COLOR_GRAY_LIGHTER};
    color: ${({ theme }) => theme.COLOR_TEXT_MUTED};
  }

  &:first-of-type {
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-of-type {
    border-top-width: 1px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  ${focusStateInset}
`;

export const Footer = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 1;

  ${({ theme }) => above(theme.DESKTOP_LARGE)`
    width: 100%;
  `}
`;
