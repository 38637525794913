import React, { memo } from 'react';
import PropTypes from 'prop-types';

import moment from 'moment';

import {
  Button,
  BorderedButton,
  ButtonBody,
  Cell,
  Closed,
  Day,
  LowAvailability,
  Month,
  CustomText,
  IconWrapper,
  CalendarIcon,
} from './CalendarDayCell.style';
import IbePropTypes from '../../../../../IbePropTypes';
import CAMPSITE_STATUS, { CAMPSITE_CALENDAR_STATUS } from '../../../../../constants/campsiteStatus';

import { dateIsInRange } from '../../../../../lib/dates';

import svgCalendarTick from '../../../../../static/images/icons/CalendarTick.svg';

const propTypes = {
  availabilitySearchLoading: PropTypes.bool,
  availabilityTotalLoading: PropTypes.bool,
  bookingEndDate: PropTypes.string,
  bookingStartDate: PropTypes.string,
  date: PropTypes.shape(IbePropTypes.moment).isRequired,
  disabled: PropTypes.bool.isRequired,
  endDate: PropTypes.string,
  handleClick: PropTypes.func.isRequired,
  hasLowAvailability: PropTypes.bool,
  isBookedPitch: PropTypes.bool,
  isLastDayInOpenRange: PropTypes.bool,
  isSearchFormPicker: PropTypes.bool.isRequired,
  isSelectedEventClosed: PropTypes.bool,
  isSelectedEventClosedLabel: PropTypes.string,
  showMemberBookableOnly: PropTypes.bool,
  loading: PropTypes.bool,
  lowAvailabilityLabel: PropTypes.string,
  month: PropTypes.string,
  placeholder: PropTypes.string,
  showMonth: PropTypes.bool,
  showRemainder: PropTypes.bool.isRequired,
  startDate: PropTypes.string.isRequired,
  text: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  showFirstMonth: PropTypes.bool,
  onRequest: PropTypes.bool,
  'data-testid': PropTypes.string,
};

const defaultProps = {
  availabilitySearchLoading: false,
  availabilityTotalLoading: false,
  bookingEndDate: '',
  bookingStartDate: '',
  endDate: '',
  hasLowAvailability: false,
  isBookedPitch: false,
  isLastDayInOpenRange: false,
  isSelectedEventClosed: false,
  isSelectedEventClosedLabel: CAMPSITE_STATUS.CLOSED,
  showMemberBookableOnly: false,
  loading: false,
  lowAvailabilityLabel: 'LOW',
  month: '',
  placeholder: '',
  showMonth: false,
  text: '',
  showFirstMonth: false,
  onRequest: false,
  'data-testid': null,
};

function CalendarDayCell(props) {
  const {
    availabilitySearchLoading,
    availabilityTotalLoading,
    bookingStartDate,
    bookingEndDate,
    date,
    disabled,
    endDate,
    handleClick,
    hasLowAvailability,
    isBookedPitch,
    isSearchFormPicker,
    isLastDayInOpenRange,
    isSelectedEventClosed,
    isSelectedEventClosedLabel,
    showMemberBookableOnly,
    loading,
    lowAvailabilityLabel,
    month,
    placeholder,
    showMonth,
    showRemainder,
    startDate,
    text,
    visible,
    showFirstMonth,
    onRequest,
  } = props;

  const selected = date.isSame(endDate) || date.isSame(startDate);

  const isEndDate = date.isSame(endDate) && !date.isSame(startDate);

  const rangedMatch = date.isSame(startDate) || date.isSame(endDate) ||
    (date.isAfter(startDate) && date.isBefore(endDate));

  const inRange = endDate
    ? rangedMatch
    : null;

  const ButtonComponent = showFirstMonth ? BorderedButton : Button;

  const isBookedDay = isBookedPitch && dateIsInRange(date, [bookingStartDate, bookingEndDate]);

  const isLastBookedDay = isBookedPitch && date.isSame(bookingEndDate);

  const isTickableForAmend = isBookedDay && !isLastBookedDay;

  const isLoading = availabilityTotalLoading || availabilitySearchLoading;

  const isSelectedEventFull = !isLoading && text === CAMPSITE_CALENDAR_STATUS.FULL;

  const isAmend = !!bookingStartDate;

  // Don't show the 'LOW' indicator in selected cells on amends
  const isLowAndBooked = hasLowAvailability && isBookedDay;
  const isAmendingAndLowAndActive = isAmend && isLowAndBooked;

  const shouldShowLowAvailabilityIndicator =
    !isSelectedEventClosed
    && hasLowAvailability
    && !disabled
    && !isAmendingAndLowAndActive;

  // Don't show OnRequest text for cells in the selected range on amends
  const isOnRequestAndBooked = onRequest && isBookedDay;
  const isAmendingAndOnRequest = isAmend && isOnRequestAndBooked;

  // Calendar text includes prices, 'Contact Us', 'Pitch Full', etc.
  const shouldShowCalendarText =
    !isSelectedEventClosed
    && !isAmendingAndOnRequest
    && text
    && !disabled
    && !isLastDayInOpenRange
    && !isLoading
    && !isSearchFormPicker;

  return (
    <Cell
      disabled={disabled}
      visible={visible}
    >
      {(showRemainder || visible) &&
        <ButtonComponent
          aria-label="Calendar day"
          type="button"
          disabled={disabled || loading}
          visible={visible}
          inRange={inRange}
          selected={selected}
          hasPrice={!!text}
          notAllowed={showMemberBookableOnly}
          onClick={() => handleClick(date, isLastDayInOpenRange)}
          data-testid={
            props['data-testid'] ? props['data-testid'] : null
          }
          isSelectedEventClosed={isSelectedEventClosed}
          isSelectedEventFull={isSelectedEventFull}
          isLastSelectedDay={isEndDate}
          isBookedDay={isBookedDay}
          isLastBookedDay={isLastBookedDay}
          isSearchFormPicker={isSearchFormPicker}
        >
          <ButtonBody>
            {shouldShowLowAvailabilityIndicator &&
              <LowAvailability highlight={inRange || showMemberBookableOnly}>
                {lowAvailabilityLabel}
              </LowAvailability>
            }
            {(!isLoading && isSelectedEventClosed) &&
              <Closed highlight={inRange} isSelectedEventClosed={isSelectedEventClosed}>
                {isSelectedEventClosedLabel}
              </Closed>
            }
            <Day withMonth={showMonth && hasLowAvailability} hasText={placeholder !== null || text}>
              {moment(date).format('D')}
              {showMonth &&
                <Month withMonth={showMonth && hasLowAvailability}>{month}</Month>
              }
            </Day>
            {shouldShowCalendarText &&
              <CustomText
                contain={showMemberBookableOnly || onRequest}
                isLastSelectedDay={isEndDate}
                isSelectedEventClosed={isSelectedEventClosed}
                isSelectedEventFull={isSelectedEventFull}
                isSelectedEventContact={onRequest}
              >
                <div>{text}</div>
              </CustomText>
            }
            {(!text && !disabled && placeholder) &&
              <CustomText
                isLastSelectedDay={isEndDate}
                isSelectedEventClosed={isSelectedEventClosed}
              >
                <div>{placeholder}</div>
              </CustomText>
            }
            {isTickableForAmend && (
              <IconWrapper>
                <CalendarIcon
                  icon={svgCalendarTick}
                  isSelected={selected || inRange}
                  isBookedDay={isBookedDay}
                  isLastSelectedDay={isEndDate}
                />
              </IconWrapper>
            )}
          </ButtonBody>
        </ButtonComponent>
      }
    </Cell>
  );
}

CalendarDayCell.propTypes = propTypes;
CalendarDayCell.defaultProps = defaultProps;

export default memo(CalendarDayCell);
