import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'next/router';
import {
  Query, compose, graphql, withApollo,
} from 'react-apollo';
import { format } from 'date-fns';
import { isEqual } from 'lodash';

import {
  getPayloadFromQuoteAndQuery,
  generateDefaultPartyMembers,
  getCampingPayloadFromQuote,
} from '../../../lib/helpers/quote';
import { parseVariables, getTotalCost } from '../../../lib/availability';
import { getProductWithCampsiteType } from '../../../lib/campsiteTypes';
import { generateCampingState, canAddMoreCampingPitches } from '../../../lib/helpers/availability';
import scrollToTop from '../../../lib/scrollToTop';

import { dictionaryItem } from '../../../hocs/withDictionary';

import Availability, { AvailabilitySection } from '../../Availability';
import AvailabilityAdvancedGuest from '../../Availability/AvailabilityAdvancedGuests';
import AvailabilityAmend from '../../Amend/AvailabilityAmend';
import AvailabilitySearchQuery from '../../Availability/AvailabilitySearchQuery';
import AvailabilityDetails from '../../AvailabilityDetails';
import QuoteCampingGuest from '../QuoteCampingGuests/QuoteCampingGuests';
import { LoadingSpinner } from '../../ui/Loading';
import { Text } from '../../ui';
import { Body } from '../../SiteCard/SiteCard.style';

import GET_AVAILABILITY_DETAILS from '../../AvailabilityDetails/graphql/getAvailabilityDetails.gql';
import GET_CONFIGURATION from '../../../config/graphql/getConfiguration';
import GET_MEMBER_STATUS from '../../../config/graphql/getUser';
import GET_BOOKING_DETAILS from '../../ManageMyBookings/graphql/getCurrentUserBooking.gql';
import isLoggedIn, { isLoggedInMember, isLoggedInMemberOrInRenewal } from '../../../lib/isLoggedIn';
import { DIALOG_MEMBERSHIP_TYPE } from '../../../config/dialogMembership';
import { getMembershipDialogProps, getDialogType } from '../../../lib/helpers/dialogMembership';
import quoteMembershipTypes, {
  getENumWithValue, newMemberTypes,
} from '../../../config/quoteMembershipTypes';
import { RENEWAL_STATUSES } from '../../../config/membershipStatus';
import MembershipDialog from '../../Dialog/MembershipDialog';
import { updateQuoteCache } from '../../../resolvers/quote';

import withStateMutation from '../../../hocs/withStateMutation';
import UPDATE_QUOTE from '../graphql/updateQuote';
import IbePropTypes from '../../../IbePropTypes';
import PLACE_EVENT_TYPES from '../../../config/eventTypes';
import { ids } from '../../../config/campsiteTypes';
import FetchPolicy from '../../../constants/FetchPolicy';
import AddToBasket from '../../AddToBasket/AddToBasket';
import { parsePayload } from '../../AvailabilityDetails/AvailabilityDetailsFooter';
import updateRouterQuery from '../../../lib/updateRouterQuery';
import routes from '../../../constants/routes';
import { redirectViaRouterToLoginWithBasketOpen, addToBasketRedirect } from '../../Basket/helpers';
import { checkIfEventIsClosedOnWithinDateRange } from '../../Availability/helpers';
import { getBookingFromData } from '../../ManageMyBookings/helpers';
import { populateQuoteFromBooking } from '../../../lib/helpers/amend';
import { bookingReducedNights } from '../../Amend/helpers';
import { addTiming, startTiming, types } from '../../../lib/timings';

/**
 * TODO: Remove all campsite specific code into seperate component
 */
class QuoteCampsite extends Component {
  static propTypes = {
    booking: PropTypes.shape(IbePropTypes.booking),
    countBookings: PropTypes.number,
    error: PropTypes.shape({}),
    loading: PropTypes.bool,
    quoteLoading: PropTypes.bool,
    addingCampingGuests: PropTypes.bool.isRequired,
    additionalCampingPitch: PropTypes.bool.isRequired,
    onlyExtras: PropTypes.bool.isRequired,
    onResultChange: PropTypes.func.isRequired,
    onSelectEvent: PropTypes.func.isRequired,
    onToggleAddingCampingGuests: PropTypes.func.isRequired,
    onToggleAdditionalCampingPitch: PropTypes.func.isRequired,
    onAdvancedGuestEdit: PropTypes.func.isRequired,
    query: PropTypes.shape(IbePropTypes.query),
    quote: PropTypes.shape(IbePropTypes.quote),
    result: PropTypes.shape(IbePropTypes.campsite),
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    toggleBasket: PropTypes.func.isRequired,
    onQueryChange: PropTypes.func.isRequired,
    data: PropTypes.shape({
      configuration: PropTypes.shape(IbePropTypes.configuration),
      loading: false,
    }),
    updateQuote: PropTypes.func.isRequired,
    membershipStatus: PropTypes.shape({
      user: PropTypes.shape(IbePropTypes.user),
    }),
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    client: PropTypes.shape(IbePropTypes.client).isRequired,
    onProductTypeMismatch: PropTypes.func.isRequired,
  };

  static defaultProps = {
    booking: null,
    countBookings: null,
    error: null,
    loading: false,
    quoteLoading: false,
    query: {},
    quote: {
      partyMembers: [],
      crossingBookings: [],
      campsiteBookings: [],
      id: '',
      extras: [],
    },
    result: {
      address: {},
    },
    data: {
      configuration: undefined,
    },
    membershipStatus: undefined,
    selectedEvent: null,
  }

  constructor(props) {
    super();

    const {
      router: {
        query: {
          bookingId,
          componentId,
          siteCode,
          isOverseas,
          campsiteId,
          eventType,
          start,
          end,
        },
      },
      query,
      quote,
      data: {
        configuration,
      },
      membershipStatus,
    } = props;

    const q = {
      ...query,
      start: query.start ?? start,
      end: query.end ?? end,
      campsiteId: query.campsiteId ?? campsiteId,
      componentId: query.componentId ?? componentId,
      siteCode: query.siteCode ?? siteCode,
      eventType,
      bookingId,
    };

    let membershipType = getENumWithValue(quoteMembershipTypes.NotLoggedInOrNonMember);

    if (isLoggedIn() &&
      (
        isLoggedInMember(membershipStatus?.user) ||
        RENEWAL_STATUSES.includes(membershipStatus?.user?.membershipStatus)
      )) {
      membershipType = getENumWithValue(quoteMembershipTypes.LoggedInMember);
    }

    const payload = getPayloadFromQuoteAndQuery(
      q, quote, membershipType, configuration, isOverseas === 'true',
      props.router.query?.componentId, props.booking,
    );
    const initialCampingPayload = getCampingPayloadFromQuote(q, quote, payload);

    // payload: current state of the form(api payload) being edited
    // payloads: state storage for payloads pending to be sent
    this.state = {
      addToBasketCallback: null,
      bookingEdited: false,
      continued: false,
      disabledForms: false,
      errors: [],
      eventChecked: false,
      maxOccupancy: null,
      memberPricesAreSelected: true,
      membershipDialog: null,
      payload,
      payloads: {
        touring: payload,
        camping: initialCampingPayload,
      },
      pitchName: payload.pitchName || null,
      startOfCalendarMonth: payload.start || format(new Date(), 'YYYY-MM-DD'),
    };

    this.basePrices = {};
    this.topRef = React.createRef();
    this.availabilitySearchQueryRef = React.createRef();
  }

  componentDidMount() {
    try {
      localStorage.removeItem('pendingLogin');
    } catch (error) {
      console.error(error);
    }
  }

  componentDidUpdate(prevProps) {
    const {
      query, quote, router, additionalCampingPitch,
    } = this.props;

    const queryChanged = !isEqual(prevProps.query, query);
    const quoteChanged = !isEqual(prevProps.quote, quote);
    const quoteDeleted = (prevProps.quote?.quoteId && !quote?.quoteId) || (
      prevProps.quote?.bookingReference && !quote?.bookingReference
    );

    if (queryChanged || quoteChanged) {
      const datesChanged = prevProps.query.start !== query.start ||
      prevProps.query.end !== query.end;
      const componentIdChanged = prevProps.query.componentId !== query.componentId ||
        query.bookingId;

      this.setPayload(datesChanged, componentIdChanged, quoteDeleted);
    }
    if (quote?.nonMemberSupplementsCost !== undefined) {
      this.handleCheckMembership(null, quote);
    }

    if (prevProps?.router?.query.eventType !== router.query.eventType &&
      !!this.state.payload?.pitchTypeId) {
      this.resetPitchTypeId();
    }

    if (prevProps?.additionalCampingPitch && !additionalCampingPitch) {
      this.onAdditionalCampingPitchBack();
    }
    if (router.query.componentId) {
      this.checkEventType();
    }
  }

  checkEventType = () => {
    const {
      quote, router, result, onAdvancedGuestEdit,
    } = this.props;
    const { eventChecked } = this.state;
    const { componentId } = router.query ?? {};
    if ((quote?.quoteId || quote?.bookingReference) && result?.pitches?.length && !eventChecked) {
      this.setState({
        eventChecked: true,
      });
      const booking = quote.campsiteBookings.find(
        campsiteBooking => campsiteBooking.id?.includes(componentId),
      );
      if (!booking) {
        return;
      }
      const pitch = booking?.pitches.find(pitchItem => pitchItem.id === componentId);
      if (pitch) {
        const campsitePitch = result.pitches.find(pitchItem => pitchItem.id === pitch.code);
        if (pitch.parentPitchId) {
          onAdvancedGuestEdit();
        }
        if (campsitePitch && campsitePitch.eventType !== Number(router.query.eventType)) {
          updateRouterQuery(routes.sites, {
            eventType: campsitePitch.eventType,
          });
        }
      }
    }
  }

  resetPitchTypeId = () => {
    this.setState({
      payload: {
        ...this.state.payload,
        pitchTypeId: undefined,
      },
    });
  }

  setPayload = (datesChanged, componentIdChanged, quoteDeleted) => {
    const {
      router: {
        query: {
          componentId,
          siteCode,
          isOverseas,
          campsiteId,
          eventType,
          bookingId,
        },
      },
      query,
      quote,
      data: {
        configuration,
      },
      additionalCampingPitch,
      addingCampingGuests,
      booking,
    } = this.props;
    const advancedGuestMode = additionalCampingPitch && addingCampingGuests;

    const { payload, payloads } = this.state;

    if ((advancedGuestMode && payload.parentPitchId) || payloads.camping.length) {
      // Avoid overriding payload state if already set
      return;
    }

    const q = {
      ...query,
      campsiteId: query.campsiteId || campsiteId,
      componentId: query.componentId || componentId,
      siteCode: query.siteCode || siteCode,
      eventType,
      bookingId,
    };

    const membershipType = this.getMembershipType();
    const generatedPayload = getPayloadFromQuoteAndQuery(
      q, quote, membershipType, configuration, isOverseas === 'true',
      this.props.router.query?.componentId, booking,
    );

    const newPayload = {
      ...generatedPayload,
      ...payload,
      // Reset payload when editing a different campsite or quote is cleared
      ...((componentIdChanged || quoteDeleted) ? generatedPayload : {}),
      arrivalTime: payload.arrivalTime || generatedPayload.arrivalTime,
      membershipType,
      start: query.start || payload.start || generatedPayload.start,
      end: query.end || payload.end || generatedPayload.end,
    };
    if (datesChanged && !componentIdChanged) {
      newPayload.guests = [];
      newPayload.pitchTypeId = payload.pitchTypeId || generatedPayload.pitchTypeId;
    }
    const newPayloads = this.getPayloads(payload);
    this.setState({
      payload: newPayload,
      payloads: newPayloads,
    });
  }

  getMembershipType = () => {
    const { user } = this.props.membershipStatus ?? {};
    if (isLoggedIn() && (isLoggedInMember(user) ||
    RENEWAL_STATUSES.includes(user?.membershipStatus))) {
      return getENumWithValue(quoteMembershipTypes.LoggedInMember);
    }
    const { membershipType } = this.props.quote ?? {};
    if (newMemberTypes.includes(membershipType)) {
      return membershipType;
    }

    return getENumWithValue(quoteMembershipTypes.NotLoggedInOrNonMember);
  }

  /**
   * @summary Fetch total price and create quote
   * @arg {Object[]} availability The AvailabilitySearch response (costPerNight: false)
   */
  handleContinueClick = (value) => {
    const continued = typeof (value) === 'undefined' ? !this.state.continued : value;
    this.setState({ continued, disabledForms: true }, () => {
      startTiming(types.AVAILABILITY_DETAILS_FOOTER);
    });
  }

  handleFormState = (disabledForms) => {
    // if (this.state.disabledForms === disabledForms) return;
    this.setState({ disabledForms });
  }

  getPayloads = (payload) => {
    const { additionalCampingPitch, addingCampingGuests } = this.props;
    const advancedGuestMode = additionalCampingPitch && addingCampingGuests &&
      payload.eventType === PLACE_EVENT_TYPES.CAMPING.id;
    const campingPayload = this.state.payloads.camping?.length > 1 ? [
      ...this.state.payloads.camping.slice(0, -1), payload,
    ] : [payload];
    const payloads = advancedGuestMode ? ({
      ...this.state.payloads, camping: campingPayload,
    }) : ({ ...this.state.payloads, touring: payload });
    return payloads;
  }

  updatePayload = (payload, cb) => {
    const payloads = this.getPayloads(payload);
    this.setState({ continued: false, payload, payloads }, cb);
  }

  // essentially same as above but we don't want to reset the continued state
  updatePayloads = (payload) => {
    const payloads = this.getPayloads(payload);
    this.setState({ payload, payloads });
  }

  cleanErrors = () => this.setState({ errors: [] });

  handleChange = (state, cb = () => null) => {
    const payloads = this.getPayloads(state.payload || this.state.payload);
    const newState = { ...this.state, ...state };
    newState.payloads = payloads;
    this.setState({
      ...newState,
      bookingEdited: true,
    }, () => cb(state));
  };

  handleMemberNonMemberChange = (memberPricesAreSelected) => {
    this.setState({
      memberPricesAreSelected: memberPricesAreSelected || !this.state.memberPricesAreSelected,
    }, () => {
      this.setPayload();
    });
  };

  handleCloseDialog = () => {
    this.setState({
      membershipDialog: null,
    });
  }

  handleUpdateQuoteMembershipType = async (membershipType) => {
    const { data, result } = this.props;
    const product = getProductWithCampsiteType(
      result?.type, data?.configuration.products,
    );
    const productCode = product?.productCode;
    const payload = {
      membershipType,
      productCode,
    };

    this.handleFormState(true);

    await this.props.updateQuote(payload)
      .then(this.handleBasketCallback)
      .catch(error => console.error(error))
      .finally(() => this.handleFormState(false));
  };

  handleUpdateQuote = async (newPayload) => {
    const { quote, data, result } = this.props;
    const product = getProductWithCampsiteType(
      result?.type, data?.configuration.products,
    );
    const productCode = product?.productCode;
    const payload = {
      ...newPayload,
      productCode,
      errataAccepted: true,
    };

    delete payload.specReq; // cleaning request
    this.handleFormState(true);

    if (
      quote.campsiteBookings &&
      quote.crossingBookings &&
      !quote.campsiteBookings.length &&
      !quote.crossingBookings.length &&
      quote.partyMembers.length === 1) {
      payload.partyMembers[0].personId = quote.partyMembers[0]?.personId;
    }

    await this.props.updateQuote(payload)
      .then(this.handleBasketCallback)
      .catch(error => console.error(error))
      .finally(() => this.handleFormState(false));
  };

  handleBasketCallback = () => {
    const { addToBasketCallback } = this.state;
    const pendingLogin = localStorage.pendingLogin === 'true';
    if (addToBasketCallback && !pendingLogin) {
      const basketCallback = addToBasketCallback;
      this.setState({
        addToBasketCallback: null,
        membershipDialog: null,
      }, () => {
        basketCallback();
      });
    }
    if (pendingLogin) {
      this.redirectToLogin();
    }
  }

  redirectToLogin = () => {
    redirectViaRouterToLoginWithBasketOpen();
  }

  handleLogin = (addToBasket) => {
    // indicator of pending campsite update
    localStorage.pendingCampsiteAdd = this.props.result?.name;
    this.setState({
      addToBasketCallback: null,
    }, async () => {
      if (addToBasket) {
        localStorage.pendingLogin = 'true';
        await addToBasket();
      }
      this.redirectToLogin();
    });
  }

  handleCheckMembership = (callback, newQuote, payload) => {
    const {
      data,
      result,
      quote,
      membershipStatus,
    } = this.props;
    const user = membershipStatus?.user;
    const { addToBasketCallback, membershipDialog, memberPricesAreSelected } = this.state;
    const dialogType =
      getDialogType(
        { ...newQuote, ...quote },
        result,
        memberPricesAreSelected,
        user,
        { ...payload, ...this.state.payload },
        data?.configuration,
        this.state.payloads,
      );
    const amount = newQuote?.nonMemberSupplementsCost ?? quote?.nonMemberSupplementsCost;
    const membershipCost = data?.configuration?.membershipTypes?.directDebit?.costSingle;

    if (payload) {
      if (dialogType === DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_REQUIRED && !membershipDialog) {
        const newPayload = {
          ...payload,
          membershipType: getENumWithValue(quoteMembershipTypes.MembershipByDD),
        };
        const primaryAction = async () => {
          await callback(newPayload);
          localStorage.pendingCampsiteAdd = this.props.result?.name;
          this.handleCloseDialog();
        };
        this.setState({
          membershipDialog: {
            type: dialogType,
            primaryAction,
            secondaryAction: this.handleCloseDialog,
            handleLogin: () => this.handleLogin(primaryAction),
            amount: membershipCost,
          },
        });
      } else {
        if (!dialogType) {
          localStorage.pendingCampsiteAdd = this.props.result?.name;
        }
        callback(payload);
      }
    } else if (!newQuote && callback) {
      if (!dialogType) {
        localStorage.pendingCampsiteAdd = this.props.result?.name;
      }
      this.setState({
        addToBasketCallback: callback,
      });
    } else if (!dialogType && addToBasketCallback) {
      this.handleBasketCallback();
    } else if (dialogType && addToBasketCallback && !membershipDialog) {
      const directDebitMembershipType = getENumWithValue(quoteMembershipTypes.MembershipByDD);

      if (dialogType === DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_SAVE) {
        this.setState({
          membershipDialog: {
            type: dialogType,
            primaryAction: () => {
              this.handleUpdateQuoteMembershipType(directDebitMembershipType);
              this.handleCloseDialog();
            },
            secondaryAction: this.handleBasketCallback,
            handleLogin: () => this.handleLogin(),
            amount,
            amountSecondary: membershipCost,
          },
        });
      }
      if (dialogType === DIALOG_MEMBERSHIP_TYPE.MEMBERSHIP_AUTO) {
        this.setState({
          membershipDialog: {
            type: dialogType,
            primaryAction: () => {
              this.handleUpdateQuoteMembershipType(directDebitMembershipType);
              this.handleCloseDialog();
            },
            handleLogin: () => this.handleLogin(),
            amount,
            amountSecondary: membershipCost,
          },
        });
      }
    }
    return dialogType;
  }

  onAdditionalCampingPitch = () => {
    const {
      onToggleAdditionalCampingPitch,
      result,
    } = this.props;
    const campingEvent = result?.events?.find(
      (event) => event.eventType === PLACE_EVENT_TYPES.CAMPING.id,
    );
    if (campingEvent) {
      onToggleAdditionalCampingPitch();
      scrollToTop();
    }
  };

  onSelectCampingEvent = () => {
    const {
      additionalCampingPitch,
      onToggleAddingCampingGuests,
      onToggleAdditionalCampingPitch,
      onSelectEvent,
      result,
    } = this.props;
    const campingEvent = result?.events?.find(
      (event) => event.eventType === PLACE_EVENT_TYPES.CAMPING.id,
    );
    if (campingEvent) {
      onSelectEvent(campingEvent);
      onToggleAddingCampingGuests();
    }
    if (!additionalCampingPitch) {
      onToggleAdditionalCampingPitch();
    }
  }

  onAddingCampingGuests = () => {
    const {
      result,
      data,
    } = this.props;
    const campingEvent = result?.events?.find(
      (event) => event.eventType === PLACE_EVENT_TYPES.CAMPING.id,
    );
    const defaultParty = generateDefaultPartyMembers(
      data?.configuration, result.type === ids.OVERSEAS_SITE,
    );
    const { payload, payloads } = this.state;
    const touringPayload = { ...payload, parentPitchId: 0 };

    if (campingEvent) {
      this.setState({
        payloads: {
          ...payloads,
          touring: touringPayload,
        },
        payload: generateCampingState(payload, defaultParty),
      }, this.onSelectCampingEvent);
    }
  }

  onAdditionalCampingPitchBack = async () => {
    const {
      onSelectEvent,
      result,
    } = this.props;
    const { payloads } = this.state;
    const touringEvent = result?.events?.find(
      (event) => event.eventType === PLACE_EVENT_TYPES.TOURING.id,
    );
    const campingPayload = payloads.camping;
    campingPayload.pop();
    if (touringEvent) {
      this.setState({
        payload: payloads?.touring,
        payloads: {
          ...payloads,
          camping: campingPayload,
        },
      });

      // check if dates have been changed, if so reset to original dates
      if (
        payloads?.touring
        && (this.props.router.query.start !== payloads?.touring.start
        || this.props.router.query.end !== payloads?.touring.end)
      ) {
        await updateRouterQuery(routes.sites, {
          ...this.props.router.query,
          startDate: payloads?.touring.start,
          endDate: payloads?.touring.end,
        });

        // ensure price is updated by calling child fetchAvailabilityTotal,
        // the price can be stale when navigating back from guest pitch
        if (this.availabilitySearchQueryRef.current) {
          this.availabilitySearchQueryRef.current.fetchAvailabilityTotal();
        }
      }

      // finish up and switch back to the touring event
      onSelectEvent(touringEvent);
    }
  }

  onAddMoreCampingPitches = () => {
    // Store current payload to payloads
    // Generate a blank camping pitch payload and set it as the active payload

    const { payload, payloads } = this.state;
    const {
      data, result, addingCampingGuests, membershipStatus,
    } = this.props;
    const defaultParty = generateDefaultPartyMembers(
      data?.configuration, result.type === ids.OVERSEAS_SITE,
    );
    const isMember = isLoggedInMemberOrInRenewal(membershipStatus?.user);
    if (canAddMoreCampingPitches(payload, payloads, isMember)) {
      if (!addingCampingGuests) {
        this.onSelectCampingEvent();
      }
      const isCampingPayload = Number(payload.eventType) === PLACE_EVENT_TYPES.CAMPING.id;
      const newPayloads = { ...payloads };
      const newCampingPayload = generateCampingState(newPayloads.touring, defaultParty);

      if (isCampingPayload) {
        newPayloads.camping = [
          ...payloads.camping,
          payload,
        ];
      } else {
        newPayloads.touring = payload;
        newPayloads.camping = [
          ...newPayloads.camping,
          newCampingPayload,
        ];
      }
      this.setState({
        payload: generateCampingState(newPayloads.touring, defaultParty),
        payloads: newPayloads,
        continued: false,
      });
      if (this.topRef.current) {
        this.topRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }

  handleAddToBasketClickCallBack = () => {
    const {
      router, client, result, toggleBasket,
    } = this.props;
    addToBasketRedirect(client, router.query, result?.name, toggleBasket);
  };

  handleAddToBasketClick = () => {
    const { client } = this.props;
    this.handleCheckMembership(() => this.handleAddToBasketClickCallBack(client));
  };

  handleSelectEvent = (selectedEvent) => {
    const newPayload = {
      ...this.state.payload,
      eventType: selectedEvent?.eventType,
    };
    const payloads = this.getPayloads(newPayload);
    this.setState({
      payload: newPayload,
      payloads,
    });
    this.props.onSelectEvent(selectedEvent);
  };

  render() {
    const {
      booking,
      countBookings,
      membershipStatus,
      onlyExtras,
      onResultChange,
      quote,
      result,
      router: {
        query: {
          campsiteId, siteCode, eventType, bookingId,
        },
      },
      toggleBasket,
      data,
      addingCampingGuests,
      additionalCampingPitch,
      onProductTypeMismatch,
    } = this.props;
    const visible = !!siteCode;

    const {
      bookingEdited,
      continued,
      errors,
      maxOccupancy,
      payload,
      payloads,
      pitchName,
      memberPricesAreSelected,
      membershipDialog,
      disabledForms,
    } = this.state;

    if (data.loading || this.props.loading || !result) {
      return <LoadingSpinner marginTop />;
    }
    const product = getProductWithCampsiteType(result?.type, data?.configuration.products);
    const dialogMembershipProps =
      getMembershipDialogProps({ dialogType: membershipDialog?.type });

    if (additionalCampingPitch && !addingCampingGuests) {
      return (
        <QuoteCampingGuest
          campingMiniImage={data?.configuration?.campingMiniImage}
          onToggleAddingCampingGuests={this.onAddingCampingGuests}
          handleCheckMembership={this.handleCheckMembership}
          onSubmit={this.handleFormState}
          payload={this.state.payloads?.touring}
          campsiteName={result?.name}
          open={!!membershipDialog}
          loading={disabledForms || this.props.loading}
          dialogMembershipProps={dialogMembershipProps}
          membershipDialog={membershipDialog}
        />
      );
    }

    const advancedGuestMode = additionalCampingPitch && addingCampingGuests;
    let AvailabilityComponent = Availability;
    if (advancedGuestMode) {
      AvailabilityComponent = AvailabilityAdvancedGuest;
    }
    if (bookingId) {
      AvailabilityComponent = AvailabilityAmend;
    }

    const showContinue = !bookingId && Number(eventType) === PLACE_EVENT_TYPES.TOURING.id &&
      result.pitches?.some(pitch => pitch.eventType === PLACE_EVENT_TYPES.CAMPING.id);

    const parsedPayload = {
      ...parsePayload(this.state.payloads?.touring, null, quote?.isBookingAmendmentQuote),
      errataAccepted: true,
    };

    const isMember = isLoggedInMemberOrInRenewal(membershipStatus?.user);
    const bookings = booking?.campsiteBookings;
    const existingBooking = bookings?.find(({ id }) => id === payload.id) || bookings?.[0] || {};
    const bookedPitches = existingBooking?.pitches ?? [];

    return (
      <>
        <div ref={this.topRef} />
        <MembershipDialog
          open={!!membershipDialog}
          loading={disabledForms || this.props.loading}
          campsiteId={campsiteId}
          siteCode={siteCode}
          {...dialogMembershipProps}
          {...membershipDialog}
        />

        <AddToBasket
          size="large"
          align="center"
          onCompleted={() => this.handleFormState(false)}
          onSubmit={() => this.handleFormState(true)}
          onSuccess={() => this.handleAddToBasketClick()}
          handleCheckMembership={this.handleCheckMembership}
          payload={parsedPayload}
          hidden
        />

        {!additionalCampingPitch && !bookingId && (
          <AvailabilitySection>
            <Text dictionary={dictionaryItem('QuoteCampsite', 'Header')} />
          </AvailabilitySection>
        )}

        <AvailabilitySearchQuery
          onCompleted={({ offers }) => onResultChange({ ...result, offers })}
          booking={booking}
          isAmend={!!bookingId}
          variables={{
            calendar: {
              start: this.state.startOfCalendarMonth,
            },
            partyMembers: payload.partyMembers,
            payload: {
              start: payload.start,
              end: payload.end,
            },
            siteCode,
            productCode: product?.productCode,
            placeType: result?.type,
            pitchId: bookingId && payload?.id ? payload.id : undefined,
            pitchTypeId: payload.pitchTypeId,
          }}
          selectedEvent={this.props.selectedEvent}
          availabilitySearchQueryRef={this.availabilitySearchQueryRef}
        >
          {(availabilityProps) => {
            const {
              availabilitySearch,
              availabilitySearchLoading,
              availabilityTotal,
              fetchAvailabilitySearch,
              fetchAvailabilityTotal,
              inputDateError,
              loading,
            } = availabilityProps;
            const isSelectedEventClosed = checkIfEventIsClosedOnWithinDateRange(
              this.props.selectedEvent,
              payload.start,
              payload.end,
            );
            let closedDateError;
            if (isSelectedEventClosed) {
              closedDateError = new Error();
              closedDateError.message = dictionaryItem('AvailabilityCalendar', 'Date', 'Closed', 'Error');
            }

            // If we have results on availabilityTotal use that, fallback to availabilitySearch
            const availability = availabilityTotal?.data?.availability?.pitchTypes?.length > 0
              ? availabilityTotal
              : availabilitySearch;
            const basePrice =
              getTotalCost(availability, payload.pitchTypeId, memberPricesAreSelected, !!bookingId);

            // Store multiple prices for camping pitches for later computation
            const priceIndex = advancedGuestMode
              ? `${payload.pitchTypeId}-${payloads?.camping?.length}`
              : payload.pitchTypeId;

            if (basePrice && this.basePrices[priceIndex] !== basePrice) {
              const updatedBasePrices = {
                ...this.basePrices,
                [priceIndex]: basePrice,
              };
              this.basePrices = updatedBasePrices;
            }

            const isLoading = this.props.quoteLoading;
            const error = this.props.error || availabilitySearch.error || availabilityTotal.error;

            const availabilityParams = {
              availabilitySearch,
              availabilitySearchLoading,
              availabilityTotal,
              campsiteId,
              campsiteAddress: result.address,
              campsiteName: result.name,
              campsiteOpenDates: result.openDates,
              cleanErrors: this.cleanErrors,
              closedDateError,
              continued,
              disabledForms: this.state.disabledForms,
              error,
              errors,
              events: result.events,
              fetchAvailabilitySearch,
              fetchAvailabilityTotal,
              handleChange: this.handleChange,
              hasBookings: !!countBookings,
              inputDateError,
              loadingAvailability: loading,
              onlyExtras,
              onContinueClick: this.handleContinueClick,
              onQueryChange: this.props.onQueryChange,
              onSelectEvent: this.handleSelectEvent,
              partyMembers: quote ? quote.partyMembers : [],
              payload,
              pitchName,
              query: this.props.query,
              updatePayload: this.updatePayload,
              handleMemberNonMemberChange: this.handleMemberNonMemberChange,
              memberPricesAreSelected,
              type: result.type,
              visible,
              selectedEvent: this.props.selectedEvent,
              product,
              quote,
              handleUpdateQuote: this.handleUpdateQuote,
              advancedGuestMode,
            };

            if (isLoading) {
              return (
                <Body>
                  <LoadingSpinner marginTop />
                </Body>
              );
            }

            return (
              <Fragment>
                <AvailabilityComponent
                  {...availabilityParams}
                />

                {continued &&
                  <Query
                    onError={() => this.setState({ continued: false })}
                    query={GET_AVAILABILITY_DETAILS}
                    fetchPolicy={FetchPolicy.NETWORK_ONLY}
                    variables={parseVariables({
                      end: payload.end,
                      partyMembers: payload.partyMembers,
                      crossingBookings: payload.crossingBookings,
                      pitchTypeId: payload.pitchTypeId,
                      start: payload.start,
                      siteCode: payload.siteCode,
                      useMemberPricing: memberPricesAreSelected,
                      pitchId: bookingId && payload?.id ? payload.id : undefined,
                      productCode: product?.productCode,
                    }, 'Reservations/AvailabilityDetails')}
                    // TEMP Performance Hooks, TODO can be safely removed at any time
                    onCompleted={() => {
                      addTiming(types.AVAILABILITY_DETAILS_FOOTER, 'Load Complete', true);
                    }}
                  >
                    {response => (
                      <AvailabilityDetails
                        {...response}
                        advancedGuestMode={advancedGuestMode}
                        configuration={data?.configuration}
                        basePrice={basePrice}
                        basePrices={this.basePrices}
                        booking={this.props.booking}
                        bookingEdited={bookingEdited}
                        campsiteAddress={result.address}
                        campsiteId={campsiteId}
                        campsiteName={result.name}
                        canAddMoreCampingPitches={
                          canAddMoreCampingPitches(
                            this.state.payload, this.state.payloads, isMember,
                          )
                        }
                        componentId={this.props.router.query?.componentId}
                        disabledForms={this.state.disabledForms}
                        handleAddMoreCampingPitches={this.onAddMoreCampingPitches}
                        handleQuoteChange={this.handleChange}
                        handleCheckMembership={this.handleCheckMembership}
                        maxOccupancy={maxOccupancy}
                        memberPricesAreSelected={memberPricesAreSelected}
                        onlyExtras={onlyExtras}
                        onCompleted={this.handleFormState}
                        onSubmit={this.handleFormState}
                        payload={advancedGuestMode ? this.state.payloads.touring : payload}
                        pitchName={pitchName}
                        pitches={result?.pitches}
                        product={product}
                        updatePayloads={this.updatePayloads}
                        quote={quote}
                        quoteCount={countBookings}
                        toggleBasket={toggleBasket}
                        onAdditionalCampingPitch={
                          showContinue ? this.onAdditionalCampingPitch : undefined
                        }
                        payloads={
                          (advancedGuestMode || this.state.payloads.camping.length) ?
                            this.state.payloads : null
                        }
                        showCancellation={bookingReducedNights(payload, bookedPitches)}
                        onProductTypeMismatch={onProductTypeMismatch}
                        searchPitches={availability?.data?.availability?.pitchTypes ?? []}
                        bookedPitches={bookedPitches}
                      />
                    )}
                  </Query>
                }
              </Fragment>
            );
          }}
        </AvailabilitySearchQuery>
      </>
    );
  }
}

export default compose(
  withRouter,
  withApollo,
  graphql(GET_CONFIGURATION),
  graphql(GET_MEMBER_STATUS, {
    name: 'membershipStatus',
    options: {
      fetchPolicy: FetchPolicy.CACHE_ONLY,
    },
    skip: props => !isLoggedIn(),
  }),
  graphql(GET_BOOKING_DETAILS, {
    options: ({ router }) => ({
      fetchPolicy: 'cache-and-network',
      variables: {
        bookingReference: router.query.bookingId,
        skip: !router.query.bookingId,
      },
    }),
    props: (props) => {
      const booking = getBookingFromData(props.data);
      const quote = populateQuoteFromBooking(props.ownProps.quote, booking);
      return {
        booking,
        quote,
      };
    },
  }),
  graphql(UPDATE_QUOTE, {
    props: ({ mutate }) => ({
      options: {
        notifyOnNetworkStatusChange: true,
      },
      updateQuote: input => mutate({
        update: updateQuoteCache,
        variables: { input },
        refetchQueries: ['Quote'],
      }),
    }),
  }),
  withStateMutation({ name: 'updateQuote' }),
)(QuoteCampsite);
