import { CAMPSITE_SORT_TYPES } from '../campsiteSearch';
import * as _Types from '../jsdocTypedefs';

/**
 * Gets the default expected sort order given the config & current querystring state
 * @param {_Types.Router} queryObject
 * @returns {number}
 */
export const getDefaultSortOrder = (queryObject) => {
  const isOverseas = queryObject.isOverseas === 'true';

  // Default to club campsites first for UK
  const overseasSortType = queryObject.searchBy === 'campsite'
    ? CAMPSITE_SORT_TYPES.SITE_NAME
    : CAMPSITE_SORT_TYPES.DISTANCE_ASC;

  const sortBy = isOverseas
    ? overseasSortType
    : CAMPSITE_SORT_TYPES.CLUB_SITES_FIRST;

  return sortBy;
};

export default {};
