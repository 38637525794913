import styled from 'styled-components';

import { Title, Button as DefaultButton, RichText } from '../ui';

export const cardPaddingInRem = 1.75;

export const Outer = styled.div`
  background: ${props => props.theme.COLOR_PEACH};
`;

const Content = styled.div`
  padding: 1rem;
  border-top: ${({ theme }) => `1px solid ${theme.COLOR_GRAY_LIGHT}`};
`;

export const Inner = styled(Content)`
  display: flex;
  flex-direction: column;
  padding: ${props => (props.isOpen
    ? `${cardPaddingInRem / 2}rem ${cardPaddingInRem}rem ${(cardPaddingInRem / 2) + 0.3}rem ${cardPaddingInRem}rem`
    : `${cardPaddingInRem / 2}rem ${cardPaddingInRem}rem`
  )};
  gap: ${props => (props.isOpen ? '5px' : '0px')};
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ImportantInformationTitle = styled(Title)`
  font-family: ${({ theme }) => theme.TEXT_HEADING_FONT};
  text-transform: none;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.COLOR_GRAY_DARK};
`;

// Font-family currently not set as body text font globally. Client requested font.
export const ImportantInformationText = styled(RichText)`
  display: inline;
  font-family: "FSElliotWebRegular", sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.COLOR_GRAY_DARK};
`;

export const InformationOuter = styled.div`
  overflow: hidden;
  max-height: 0px;
  transition: ${props => (props.isOpen ? 'max-height ease-out 0.2s' : 'max-height ease-in 0.125s')};
`;

export const ImportantInformationLink = styled.a`
  display: inline;
  font-family: "FSElliotWebRegular", sans-serif;
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  text-align: left;
  text-decoration: underline;
`;

export const InformationInner = styled.div`
  margin-top: -5px;
`;

export const Button = styled(DefaultButton)`
  height: 2rem;
`;
