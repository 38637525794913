import styled, { css } from 'styled-components';

import Icon from '../../Icon';
import { focusState } from '../Form.style';
import CHECKBOX_VARIANTS from './consts';

export default styled.span`
  display: inline-block;
`;

export const Input = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
`;

export const CheckIcon = styled(Icon)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0.25rem;
  color: ${({ theme }) => theme.COLOR_TEXT_BODY};
`;

export const CheckboxLabel = styled.label.attrs(props => ({
  tabIndex: 0,
}))`
  display: inline-block;
  flex: 0 0 1.5rem;
  position: relative;
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid ${({ theme }) => theme.COLOR_GRAY};
  background-color: ${({ theme }) => theme.COLOR_WHITE};
  user-select: none;

  ${({ customStyled }) => customStyled && css`${customStyled}`}

  background-color: ${({ theme, disabled }) => (disabled ? theme.COLOR_GRAY_LIGHTEST : theme.COLOR_WHITE)};
  ${focusState}

/* Variant Styles */
  ${({ theme, variant }) => {
    const variantStyles = {
      [CHECKBOX_VARIANTS.FILLED]: css`
        border-radius: 5px;
        border: 2px ${theme.COLOR_CYAN_DARK} solid;

        ${Input}:checked + & {
          background-color: ${theme.COLOR_CYAN_DARK};
          color: ${theme.COLOR_WHITE};

          & ${CheckIcon} {
            color: ${theme.COLOR_WHITE};
          }
        }
      `,
      // Add any future variant styles here
    };

    return variant ? variantStyles[variant] : null;
  }}

  ${({ errored, theme }) => errored && css`
    outline: none;
    border: none;
    border: 2px ${theme.COLOR_AMBER_RED} solid;
  `}
`;
