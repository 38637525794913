import styled from 'styled-components';

import BasketToggle from '../Basket/BasketToggle';
import zIndex from '../../config/zIndex';
import { above } from '../../styles/libs';

export const BasketToggleMobile = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

export const BasketButton = styled(BasketToggle)`
  position: sticky;
  top: 0;
  height: 54px;
  z-index: ${zIndex.IMPORTANT_CONTENT + 1};
`;

export const MapWrapper = styled.div`
  height: 100%;
  min-height: 75vh;

  ${({ isBookingWidget, theme }) => above(theme.TABLET)`
    display: ${isBookingWidget ? 'none' : 'flex'};
    position: sticky;
    top: 0;
    flex: 1;
    height: 100vh;
    margin-right: 3rem;
  `}
`;

export const Section = styled.section`
  display: flex;
  position: relative;
  background-color: ${({ theme, isBookingWidget }) => (isBookingWidget ? 'transparent' : theme.SECTION_BACKGROUND_COLOR)};
  width: 100%;
  // Fallback to old value (85vh) if dvh is not supported
  min-height: 85vh;
  min-height: 100dvh;

  ${({ theme, isBookingWidget }) => !isBookingWidget && above(theme.MOBILE_LARGE)`
    @media screen and (min-height: 768px) {
      min-height: 768px;
    }
  `}

  ${({ theme }) => above(theme.TABLET)`
    @media screen and (min-height: 1024px) {
      min-height: 1024px;
    }
  `}
`;

export const Main = styled.main`
  position: relative;
  height: 100vh;
  width: 100%;
`;
