import React, { PureComponent, Fragment } from 'react';
import { compose, withApollo } from 'react-apollo';
import PropTypes from 'prop-types';
import { isEmpty } from 'lodash';

import { dictionaryItem } from '../../hocs/withDictionary';
import { mapDictionaryItems } from '../../hocs/withDictionary/utils';

import Modal, { ModalClose, ModalContent } from '../ui/Modal';
import PitchBody from './PitchBody';
import { ButtonInfo } from '../ui/Button';
import { MessageDefault, MessageWarning } from '../ui/Message';
import { RichText, Text, Title } from '../ui';
import Select from '../ui/Form/Select';
import { Label } from '../ui/Form';
import {
  Header,
  HeaderLeft,
  HeaderRight,
  PitchToggleButton,
} from './AvailabilityPitches.style';
import {
  getDefaultSort,
  getPitchHasAvailabilityForDates,
  PITCH_SORT_LABELS,
  PITCH_SORT_TYPES,
} from '../../lib/availability';

import svgInfo from '../../static/images/icons/Info.svg';
import IbePropTypes from '../../IbePropTypes';

class AvailabilityPitches extends PureComponent {
  static defaultProps = {
    disabled: false,
    end: undefined,
    error: false,
    pitches: [],
    pitchTypeId: '',
    showPrice: false,
    amend: false,
    start: undefined,
    sortType: undefined,
  };

  static propTypes = {
    disabled: PropTypes.bool,
    end: PropTypes.string,
    error: PropTypes.bool,
    handlePitchChange: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    memberPricesAreSelected: PropTypes.bool.isRequired,
    pitches: PropTypes.arrayOf(PropTypes.shape(IbePropTypes.pitch)),
    pitchTypeId: PropTypes.string,
    pitchTypesLink: PropTypes.string.isRequired,
    showPrice: PropTypes.bool,
    client: PropTypes.shape().isRequired,
    amend: PropTypes.bool,
    start: PropTypes.string,
    sortType: PropTypes.string,
    handleSortTypeChange: PropTypes.func.isRequired,
  };

  state = {
    isModalOpen: false,
  }

  componentDidMount() {
    this.handleDefaultPitch();
  }

  componentDidUpdate() {
    this.handleDefaultPitch();
  }

  handleModalToggle = () => {
    this.setState({ isModalOpen: !this.state.isModalOpen });
  }

  getMinPricePerNightProperty = () => (
    this.props.memberPricesAreSelected ? 'memberMinPricePerNight' : 'nonMemberMinPricePerNight'
  );

  getAvailabilityProperty = () => (
    this.props.memberPricesAreSelected ? 'memberAvailability' : 'nonMemberAvailability'
  );

  handleDefaultPitch = () => {
    // Prevent pitch selection on page load
    if (this.props.loading) return;

    // Ignore if a pitch is selected
    if (this.props.pitchTypeId) return;
    const { handlePitchChange, pitches } = this.props;

    const minPricePerNightProperty = this.getMinPricePerNightProperty();

    // Select the cheapest pitch by default
    const cheapestPitch = pitches
      .filter(pitch => !!pitch[minPricePerNightProperty] && getPitchHasAvailabilityForDates(
        this.props.start,
        this.props.end,
        pitch.calendarFirstCellDate,
        pitch[this.getAvailabilityProperty()],
      ))
      .reduce((acc, cur) => {
        if (!acc[minPricePerNightProperty]) {
          return cur;
        }

        return acc[minPricePerNightProperty] < cur[minPricePerNightProperty] ? acc : cur;
      }, {});

    // Else select the first pitch
    const [firstPitch] = pitches;

    const selectedPitch = isEmpty(cheapestPitch) ? firstPitch : cheapestPitch;

    handlePitchChange(selectedPitch);

    if (!this.props.sortType) {
      this.props.handleSortTypeChange(getDefaultSort(pitches));
    }
  }

  render() {
    const {
      amend,
      disabled,
      error,
      loading,
      handlePitchChange,
      pitches,
      pitchTypesLink,
      showPrice,
      client,
      sortType,
      handleSortTypeChange,
    } = this.props;

    const minPricePerNightProperty = this.getMinPricePerNightProperty();
    const sortOptions = mapDictionaryItems(client, PITCH_SORT_LABELS);

    return (
      <Fragment>
        <Modal active={this.state.isModalOpen} overlay>
          <ModalContent>
            <ModalClose onClick={this.handleModalToggle} />
            <RichText
              dictionary={dictionaryItem('AvailabilityPitches', 'Modal')}
            />
          </ModalContent>
        </Modal>

        <Header>
          <HeaderLeft>
            <Title
              dictionary={
                amend
                  ? dictionaryItem('AvailabilityAmend', 'Pitch')
                  : dictionaryItem('AvailabilityPitches')
              }
              tag={1}
              size={4}
            />
            <ButtonInfo
              ariaLabel="Information"
              icon={svgInfo}
              marginLeft="0.5rem"
              noPadding
              onClick={this.handleModalToggle}
              type="button"
            />
          </HeaderLeft>
          {!amend && (
            <HeaderRight>
              <Label
                dictionary={dictionaryItem('AvailabilityPitches', 'Sort')}
                htmlFor="sort-pitches"
              />
              <Select
                id="sort-pitches"
                name="prefix"
                value={sortType}
                onChange={(e) => {
                  handleSortTypeChange(e.target.value);
                }}
              >
                {Object.values(PITCH_SORT_TYPES).map((pitchSortType) => (
                  <option value={pitchSortType} key={pitchSortType}>
                    {sortOptions[pitchSortType]}
                  </option>
                ))}
              </Select>
            </HeaderRight>
          )}
        </Header>

        {pitches && !pitches.length && (
          <Text
            dictionary={dictionaryItem('AvailabilityPitches', 'NoPitches')}
            align="center"
            marginTop
          />
        )}

        {pitches &&
          pitches.map((pitch, index) => {
            const { selected } = pitch;
            const isDisabled = disabled || loading;
            const minPricePerNight = pitch[minPricePerNightProperty];

            return (
              <PitchToggleButton
                disabled={isDisabled}
                key={pitch.id}
                onClick={() => handlePitchChange(pitch)}
                selected={selected}
                data-testid={`availability-pitches__button-${index}`}
              >
                <PitchBody
                  selected={selected}
                  disabled={isDisabled}
                  loading={loading}
                  name={pitch.name}
                  description={pitch.description}
                  minPricePerNight={minPricePerNight}
                  pitchTypesLink={pitchTypesLink}
                  showPrice={showPrice}
                />
              </PitchToggleButton>
            );
          })}

        {!showPrice && (
          <MessageDefault
            dictionary={dictionaryItem('AvailabilityPitches', 'NoDates')}
            marginTop
          />
        )}

        {!loading && error && (
          <MessageWarning>
            We were not able to find any availability for this campsite at the
            dates provided.
          </MessageWarning>
        )}
      </Fragment>
    );
  }
}

export default compose(
  withApollo,
)(AvailabilityPitches);
