import { stringify } from 'query-string';
import { isEqual } from 'lodash';
import { toUTC } from '../../lib/dates';

import * as _Types from '../../lib/jsdocTypedefs';
import * as _AvailabilityTypes from './types';

export const checkIfEventIsClosedOnDate = (event, date) => {
  const dateTime = toUTC(date).getTime();
  if (event?.openDates?.length) {
    return !event.openDates.some((dataRange) => (
      dateTime >= toUTC(dataRange.fromDate).getTime()
        && dateTime <= toUTC(dataRange.toDate).getTime()
    ));
  }
  return false;
};

export const checkIfEventEndsOnDate = (event, date) => {
  const dateTime = date instanceof Date
    ? date.getTime() : date.toDate().getTime();

  if (event?.openDates?.length) {
    return event.openDates.some((dataRange) => (
      dateTime === new Date(dataRange.toDate).getTime()
    ));
  }

  return false;
};

export const checkIfEventIsClosedOnWithinDateRange = (event, start, end) => {
  if (start && end && event) {
    const startTime = start instanceof Date
      ? start.getTime() : new Date(start).getTime();

    const endTime = start instanceof Date
      ? end.getTime() : new Date(end).getTime();

    if (event?.openDates?.length) {
      return !event.openDates.some((dataRange) => (
        startTime >= new Date(dataRange.fromDate).getTime()
          && endTime <= new Date(dataRange.toDate).getTime()
      ));
    }
  }

  return false;
};

export const getIsOverseas = (router) => (
  Number(router.query?.isOverseas === 'true')
);

/**
 * Formats a party member to string for sending in a querystring
 * @param {_Types.PartyMember} partyMember
 * @param {number} index
 * @returns {string}
 */
const generatePartyMemberQueryString = (partyMember, index) => {
  const { personId, dateOfBirth, type } = partyMember;
  return `PartyMembers[${index}].PersonID=${personId}&PartyMembers[${index}].DateOfBirth=${dateOfBirth}&PartyMembers[${index}].Type=${type}`;
};

export const buildAmendmentAvailabilityPath = ({ args }) => {
  const { PartyMembers, ...stringArgs } = args;
  const stringifiedPersons = PartyMembers.map(generatePartyMemberQueryString).join('&');
  const querystring = `${stringify(stringArgs)}&${stringifiedPersons}`;
  return `BookingAmendment/BookingAmendmentAvailabilitySearch?${querystring}`;
};

/**
 * Deep-compares two membership arrays by types
 * @param {_Types.PartyMembers} members
 * @param {_Types.PartyMembers} newMembers
 * @returns {boolean} areEqual
 */
export const arePartyMemberArraysEqual = (members, newMembers) => {
  const membersTypes = members.map((member) => member.type).sort();
  const newMembersTypes = newMembers.map((member) => member.type).sort();
  return isEqual(membersTypes, newMembersTypes);
};

/**
 * Compares a quote and a booking to see if any key fields have changed
 * (e.g. party members, dates, etc.)
 * @param {_Types.Router} router
 * @param {_AvailabilityTypes.AvailabilitySearchVariables} variables
 * @param {_Types.BookingDetails} booking
 * @returns {boolean} hasChanged
 */
export const hasUpdatedQuoteFromBooking = (router, variables, booking) => {
  const { componentId } = router?.query ?? {};

  if (!booking) return false;

  const bookingCampsite = booking.campsiteBookings.find(
    (campsite) => campsite.id === componentId,
  );

  if (!bookingCampsite) return false;

  // Work out if any key price-dependent items have been changed
  // This currently just includes party members and dates
  const { partyMembers: selectedPartyMembers } = variables;

  const pitch = bookingCampsite.pitches[0];

  // We check if the party members list has changed
  const havePartyMembersChanged = !arePartyMemberArraysEqual(
    selectedPartyMembers,
    booking.partyMembers,
  );

  // Next we check if dates have been updated
  const { fromDate, toDate } = pitch.bookingDates;
  const isStartDateTheSame =
    variables.payload.start?.substring(0, 10) === fromDate?.substring(0, 10);
  const isEndDateTheSame =
    variables.payload.end?.substring(0, 10) === toDate?.substring(0, 10);
  const haveDatesChanged = !(isStartDateTheSame && isEndDateTheSame);

  return havePartyMembersChanged || haveDatesChanged;
};

export default null;
