import React, { Component } from 'react';
import { withRouter } from 'next/router';
import PropTypes from 'prop-types';
import { compose, withApollo } from 'react-apollo';
import moment from 'moment';

import {
  Icon, Text, Title, RichText,
} from '../ui';
import withPicker from '../ui/Datepicker/hocs/withPicker';
import { MONTHS } from '../ui/Datepicker/constants';
import Modal, { ModalContent } from '../ui/Modal';
import { dictionaryItem, getDictionaryItem } from '../../hocs/withDictionary';
import {
  Actions,
  Action,
  ActionBrand,
  AlertIcon,
} from '../PopUp/PopUp.styles';
import theme from '../../styles/config/theme';

import StyledAvailabilityPicker, { AvailabilityCalendar, Button, Header } from './AvailabilityPicker.style';
import svgArrowLeft from '../../static/images/icons/ArrowLeft.svg';
import svgArrowRight from '../../static/images/icons/ArrowRight.svg';
import svgError from '../../static/images/icons/Error.svg';
import IbePropTypes from '../../IbePropTypes';
import { checkIfEventIsClosedOnDate, checkIfEventEndsOnDate } from './helpers';
import { AmendsContext } from './AvailabilitySearchQuery';
import { parseAmendsAvailabilityPrices } from '../ui/Datepicker/helpers';

function formatAvailabilityPrice(price) {
  if (!price) return '';

  return `£${String(price.toFixed(2))}`;
}

class AvailabilityPicker extends Component {
  static propTypes = {
    availabilitySearchLoading: PropTypes.bool,
    booking: PropTypes.shape(IbePropTypes.booking),
    datepicker: PropTypes.shape().isRequired,
    disabled: PropTypes.bool.isRequired,
    disablePrevMonth: PropTypes.bool.isRequired,
    disableNextMonth: PropTypes.bool.isRequired,
    endDate: PropTypes.string,
    error: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.shape({}),
    ]),
    fetchAvailability: PropTypes.func.isRequired,
    fullscreen: PropTypes.bool.isRequired,
    handleNextMonth: PropTypes.func.isRequired,
    handlePrevMonth: PropTypes.func.isRequired,
    handleShowMonth: PropTypes.func.isRequired,
    handleClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    memberBookableDate: PropTypes.string.isRequired,
    memberPricesAreSelected: PropTypes.bool.isRequired,
    nonMemberBookableDate: PropTypes.string.isRequired,
    notAvailableText: PropTypes.string,
    onGoToMemberPrices: PropTypes.func.isRequired,
    onRequestText: PropTypes.string,
    selectedEvent: PropTypes.shape(IbePropTypes.event),
    selectedPitch: PropTypes.shape({
      availability: PropTypes.arrayOf(PropTypes.number),
      calendarFirstCellDate: PropTypes.string,
      daysOnRequest: PropTypes.arrayOf(PropTypes.string),
      hasLowAvailability: PropTypes.arrayOf(PropTypes.bool),
      code: PropTypes.string,
    }),
    results: PropTypes.arrayOf(PropTypes.shape({
      cells: PropTypes.arrayOf(PropTypes.shape({
        date: PropTypes.instanceOf(moment),
      })),
      days: PropTypes.number,
      month: PropTypes.number,
      year: PropTypes.number,
    })).isRequired, // these are calendars
    router: PropTypes.shape(IbePropTypes.router).isRequired,
    startDate: PropTypes.string,
    options: PropTypes.shape({}),
    userIsMember: PropTypes.bool.isRequired,
    client: PropTypes.shape({}).isRequired,
    onCancelMemberPrices: PropTypes.func.isRequired,
  }

  static defaultProps = {
    availabilitySearchLoading: false,
    booking: {},
    endDate: '',
    error: null,
    loading: false,
    notAvailableText: '',
    onRequestText: '',
    selectedEvent: {},
    selectedPitch: null,
    startDate: '',
    options: {},
  }

  static contextType = AmendsContext;

  constructor(props) {
    super(props);

    this.state = {
      isModalOpen: false,
      startDate: props.startDate,
      endDate: props.endDate,
    };
  }

  componentDidUpdate(prevProps) {
    const {
      startDate, endDate, userIsMember, memberPricesAreSelected,
    } = this.props;

    const membershipChanged =
        userIsMember !== prevProps.userIsMember ||
        memberPricesAreSelected !== prevProps.memberPricesAreSelected;
    const startDateChanged = startDate && prevProps.startDate !== startDate;
    const endDateChanged = endDate && prevProps.endDate !== endDate;
    if (startDateChanged || endDateChanged || membershipChanged) {
      this.handleChangedDates();
    }

    // detect a change in the first day of calendar to
    // auto fetch prices for that changed month.
    // oddly does not happen upon normal pagination,
    // only via clicking in date cell.
    const firstDate = this.props.results?.[0].cells?.[0].date;
    const prevFirstDate = prevProps.results?.[0].cells?.[0].date;
    if (!firstDate.isSame(prevFirstDate)) {
      this.handlePaginationClick(this.props);
    }
  }

  handlePaginationClick = ({ results: [calendar] }) => {
    const start = calendar.calendarDate.format('YYYY-MM-DD');
    this.props.fetchAvailability({ startOfCalendarMonth: start });
  }

  getAvailabilityProperty = () => (
    this.props.memberPricesAreSelected ? 'memberAvailability' : 'nonMemberAvailability'
  );

  checkShouldShowMemberBookableOnly = (cellDate) => {
    const cellDateTime = cellDate instanceof Date
      ? cellDate.getTime() : cellDate.toDate().getTime();
    const maxMemberBookableDateTime = new Date(this.props.memberBookableDate).getTime();
    const pastNonMemberBookableDate =
      cellDateTime > new Date(this.props.nonMemberBookableDate).getTime();
    const pastMaxDate = cellDateTime <= maxMemberBookableDateTime;

    return pastNonMemberBookableDate
      && !this.props.userIsMember
      && !this.props.memberPricesAreSelected
      && pastMaxDate;
  }

  handleToggleModal = (open) => {
    this.setState({ isModalOpen: open ?? !this.state.isModalOpen });
  }

  handleCancelMember = async () => {
    const currentEndDate = this.state.endDate || this.state.startDate;
    await this.props.onCancelMemberPrices();
    this.setState({
      startDate: '',
      endDate: '',
    });
    this.props.handleShowMonth(moment(currentEndDate, 'YYYY-MM-DD'));
    this.handleToggleModal(false);
  }

  handleGoToMemberPrices = () => {
    this.handleToggleModal(false);
    this.props.onGoToMemberPrices();
  }

  handleChangedDates = (clickedDate, isLastDayInOpenRange) => {
    const {
      startDate, endDate, userIsMember, memberPricesAreSelected,
    } = this.props;

    this.setState({
      startDate,
      endDate,
    });

    let startDateInvalid = false;
    let endDateInvalid = false;
    let clickedDateInvalid = false;
    if (!userIsMember && !memberPricesAreSelected) {
      if (startDate) {
        startDateInvalid = this.checkShouldShowMemberBookableOnly(moment(startDate, 'YYYY-MM-DD'));
      }
      if (endDate) {
        endDateInvalid = this.checkShouldShowMemberBookableOnly(moment(endDate, 'YYYY-MM-DD'));
      }
      if (clickedDate) {
        clickedDateInvalid = this.checkShouldShowMemberBookableOnly(clickedDate);
      }
      if (!this.state.isModalOpen && (startDateInvalid || endDateInvalid || clickedDateInvalid)) {
        this.handleToggleModal(true);
      }
    }
    if (clickedDate && !clickedDateInvalid) {
      this.props.handleClick(clickedDate, isLastDayInOpenRange);
    }
  }

  render() {
    const {
      availabilitySearchLoading,
      datepicker,
      disabled,
      disablePrevMonth,
      disableNextMonth,
      error,
      fullscreen,
      handleNextMonth,
      handlePrevMonth,
      loading,
      memberBookableDate,
      options,
      onRequestText,
      results,
      router,
      selectedPitch,
      client,
    } = this.props;
    const {
      startDate,
      endDate,
    } = this.state;
    const {
      availabilityTotal,
      availabilitySearch,
      booking,
    } = this.context;

    const availabilityProperty = this.getAvailabilityProperty();
    const text = [];
    const hasLowAvailability = [];
    const hasNoAvailability = [];
    const isLastDayInOpenRange = [];
    const isSelectedEventClosed = [];
    const showMemberBookableOnly = [];
    const maxMemberBookableDateTime = new Date(memberBookableDate).getTime();

    let hasCorrectResultsForSelectedMonth = false;
    if (results.length && results[0].cells && results[0].cells.length) {
      hasCorrectResultsForSelectedMonth
        = results[0].cells[0].date.isSame(selectedPitch?.calendarFirstCellDate);
    }

    const isAmend = !!booking;

    const calendarInfo = results?.[0] ?? {};
    const actualMonth = (calendarInfo?.month ?? 0) + 1;
    const paddedMonth = actualMonth >= 10 ? actualMonth : `0${actualMonth}`;
    const calendarStartDate = `${calendarInfo?.year}-${paddedMonth}-01`;

    const parsedPitchesWithPrices = parseAmendsAvailabilityPrices(
      {
        start: startDate,
        end: endDate,
      },
      booking,
      availabilitySearch,
      availabilityTotal,
      selectedPitch,
      router,
      calendarStartDate,
    );

    const selectedAmendPitch = parsedPitchesWithPrices.find(
      (pitch) => pitch.code === selectedPitch.code,
    );

    results.forEach(result => {
      result.cells.forEach((cell, index) => {
        const cellDate = cell.date.toDate();
        const cellDataTime = cellDate.getTime();
        // If we're amending we have special pricing
        const pitch = isAmend ? selectedAmendPitch : selectedPitch;
        const availability = pitch?.[availabilityProperty] ?? [];
        const { hasLowAvailability: isLow } = selectedPitch;

        const price = (
          availability
          && index <= availability.length
          && hasCorrectResultsForSelectedMonth
        ) ? availability[index] : null;

        const lowAvailability = (
          isLow
          && index <= isLow.length
          && hasCorrectResultsForSelectedMonth
        ) ? isLow[index] : false;

        const memberBookableOnly = this.checkShouldShowMemberBookableOnly(cellDate);
        showMemberBookableOnly.push(memberBookableOnly);
        const memberOnlyText = getDictionaryItem(client, 'AvailabilityPicker__CalendarMemberOnly__Text');

        const priceText = (cellDataTime <= maxMemberBookableDateTime && price)
          ? formatAvailabilityPrice(price)
          : formatAvailabilityPrice(null);

        text.push(memberBookableOnly ? memberOnlyText : priceText);

        hasLowAvailability.push(lowAvailability);
        isSelectedEventClosed.push(checkIfEventIsClosedOnDate(this.props.selectedEvent, cellDate));
        isLastDayInOpenRange.push(
          cell.isMaxDay || checkIfEventEndsOnDate(this.props.selectedEvent, cellDate),
        );
      });
    });

    return results.map((result, i) => {
      if (selectedPitch.daysOnRequest) {
        result.cells.forEach(({ date }, index) => {
          if (selectedPitch.daysOnRequest.includes(date.format('YYYY-MM-DD'))) {
            text[index] = onRequestText;
          }
        });
      }

      return (
        <StyledAvailabilityPicker key={`${result.year}_${result.month}`}>
          <Modal
            active={this.state.isModalOpen}
            overlay
          >
            <ModalContent size="small">
              <AlertIcon icon={svgError} color={theme.COLOR_AMBER_ORANGE} />
              <Title align="center" tag={3} size={2} marginBottom color={theme.COLOR_AMBER_ORANGE} dictionary={dictionaryItem('AvailabilityPicker', 'MemberPricesModal')} />
              <RichText dictionary={dictionaryItem('AvailabilityPicker', 'MemberPricesModal')} />
              <Actions>
                <Action
                  onClick={this.handleCancelMember}
                  dictionary={dictionaryItem('AvailabilityPicker', 'MemberPricesModalClose')}
                />
                <ActionBrand
                  onClick={this.handleGoToMemberPrices}
                  dictionary={dictionaryItem('AvailabilityPicker', 'MemberPricesModalConfirm')}
                />
              </Actions>
            </ModalContent>
          </Modal>
          <Header>
            <Button
              ariaLabel="Previous month"
              type="button"
              onClick={() => handlePrevMonth().then(res => this.handlePaginationClick(res))}
              disabled={disablePrevMonth}
            >
              <Icon
                icon={svgArrowLeft}
                size="1.125em"
              />
            </Button>
            <Text weight={600}>{MONTHS[result.month]} {result.year}</Text>
            <Button
              ariaLabel="Next month"
              type="button"
              onClick={() => handleNextMonth().then(res => this.handlePaginationClick(res))}
              disabled={disableNextMonth}
            >
              <Icon
                icon={svgArrowRight}
                size="1.125em"
              />
            </Button>
          </Header>

          <AvailabilityCalendar
            {...result}
            availabilitySearchLoading={availabilitySearchLoading}
            disabled={disabled}
            endDate={endDate}
            startDate={startDate}
            showRemainder={datepicker.showRemainder}
            fullscreen={fullscreen}
            handleClick={this.handleChangedDates}
            hasLowAvailability={hasLowAvailability}
            hasNoAvailability={hasNoAvailability}
            isLastDayInOpenRange={isLastDayInOpenRange}
            isSelectedEventClosed={isSelectedEventClosed}
            showMemberBookableOnly={showMemberBookableOnly}
            index={i}
            loading={loading}
            options={options}
            placeholder=""
            showHeader={false}
            text={text}
            width="100%"
            showFirstMonth
          />

          {(error && !loading) &&
            <div>Error!</div>
          }
        </StyledAvailabilityPicker>
      );
    });
  }
}

export default compose(
  withApollo,
  withPicker,
  withRouter,
)(AvailabilityPicker);
